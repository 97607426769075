

import React, { useState, useEffect } from "react";
import * as Yup from "yup"; // Import Yup for validation
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import axios from "../../../utils/axios";

import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../assets/charts/Header";
import { useUser } from "../../../contexts/auth";
// import { AuthContext} from "../../../contexts/auth";
const PermitUpdate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { clientId } = useParams();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ownername: "",
    guardianname: "",
    vehicleno: "",
    engineno: "",
    chassisno: "",
    startdate: null,
    expiredate: null,
    permittype: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [datas, setData] = useState({});
  const [adhardocument, setAdhardocument] = useState(null);
  const [pan, setPan] = useState(null);
  const [Rc, setRc] = useState(null);
  const [other, setOther] = useState(null);
  const [permit, setPermit] = useState(null);

  const loadpermit = async () => {
    const res = await axios.get(`/getpermitdoc/${clientId}`, {});
    setData(res.data.client);
  };
  console.log(datas);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChangeAdhar = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAdhardocument(selectedFiles);
      handleInputChange({
        target: {
          name: "adhardocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangePan = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPan(selectedFiles);
      handleInputChange({
        target: {
          name: "pandocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeRc = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setRc(selectedFiles);
      handleInputChange({
        target: {
          name: "rcdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeOther = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setOther(selectedFiles);
      handleInputChange({
        target: {
          name: "otherdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangepermit = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPermit(selectedFiles);
      handleInputChange({
        target: {
          name: "permitdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handlestartDateChange = (date) => {
    let formattedstartDate = null;

    if (date) {
      formattedstartDate = dayjs(date).isValid()
        ? dayjs(date).toISOString()
        : null;
    }

    setFormData({
      ...formData,
      startdate: formattedstartDate,
    });
  };

  const handleexpireDateChange = (date) => {
    let formattedexpireDate = null;

    if (date) {
      formattedexpireDate = dayjs(date).isValid()
        ? dayjs(date).toISOString()
        : null;
    }

    setFormData({
      ...formData,
      expiredate: formattedexpireDate,
    });
  };

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`/getPermit/${clientId}`);
        if (response.status === 200) {
          const clientData = response.data.client;
          setFormData({
            // Update formData with clientData received
            ...clientData,
            registrationdate: clientData.registrationdate
              ? dayjs(clientData.registrationdate).toDate()
              : null,
            startdate: clientData.startdate
              ? dayjs(clientData.startdate).toDate()
              : null,
            expiredate: clientData.expiredate
              ? dayjs(clientData.expiredate).toDate()
              : null,
            permittype: clientData.permittype || "",
          });
          setValues({
            ...clientData,
           
          });
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientId) {
      // Ensure itemId exists before fetching data
      fetchClientData();
      loadpermit();
    }
  }, [clientId]);

  const handleEditClick = () => {
    setIsEditMode(true); // Enable edit mode
    setIsSubmitting(false); // Reset submission status on edit click
  };




  const ValidationSchemaPermit = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    phoneno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
  });




  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    validationSchema: ValidationSchemaPermit,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      try {
        const formDataToSend = new FormData();
  
        formDataToSend.append("formData", JSON.stringify(values));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("permitdocument", permit);
        formDataToSend.append("username", username);
  
        const response = await axios.post(
          `/updatePermit/${clientId}`,
          formDataToSend
        );
        if (response.status === 200) {
          setSuccessMessage("Client Permit updated successfully");
          setOpenSuccess(true);
          setIsEditMode(false);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error updating client permit data:", error.response.data);
        setIsSubmitting(false);
      }
    },
  });





  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   try {
  //     const formDataToSend = new FormData();

  //     formDataToSend.append("formData", JSON.stringify(formData));
  //     formDataToSend.append("pandocument", pan);
  //     formDataToSend.append("adhardocument", adhardocument);
  //     formDataToSend.append("rcdocument", Rc);
  //     formDataToSend.append("otherdocument", other);
  //     formDataToSend.append("permitdocument", permit);
  //     formDataToSend.append("username", username);

  //     const response = await axios.post(
  //       `/updatePermit/${clientId}`,
  //       formDataToSend
  //     );
  //     if (response.status === 200) {
  //       setSuccessMessage("Client Permit updated successfully");
  //       setOpenSuccess(true);
  //       setIsEditMode(false);
  //       setIsSubmitting(false);
  //     }
  //   } catch (error) {
  //     console.error("Error updating client permit data:", error.response.data);
  //     setIsSubmitting(false);
  //   }
  // };

  const handlePermitFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
        <Button
            onClick={() => navigate(`/permittimeline/${clientId}`)}
            variant="contained"
          >
            Timeline
          </Button>
          <Header
            title="Permit Details Update"
            subtitle="Update your Permit details"
          />
          <Typography variant="h6" align="left">
            Service Types: <b>Permit</b>
          </Typography>
          <br></br>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Owner Name"
              onChange={handleChange}
              value={values.ownername}
              name="ownername"
              size="small"
              error={touched.ownername && !!errors.ownername}
              helperText={
                touched.ownername && errors.ownername
                  ? "owner name is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onChange={handleChange}
              value={values.address}
              name="ownername"
              size="small"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Guardian Name"
              onChange={handleChange}
              value={values.guardianname}
              name="guardianname"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Vechile Number"
              onChange={handleChange}
              value={values.vehicleno}
              name="vehicleno"
              error={touched.vehicleno && !!errors.vehicleno}
              helperText={
                touched.vehicleno && errors.vehicleno
                  ? "vehicle no is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Phone Number"
              onChange={handleChange}
              value={values.phoneno}
              name="phoneno"
              error={touched.phoneno && !!errors.phoneno}
              helperText={
                touched.phoneno && errors.phoneno
                  ? "phone no is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            {/* <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
              <InputLabel id="permittype-label">
                Select Permit Type <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                labelId="permittype-label"
                id="permittype"
                value={permitValues?.permittype || ""}
                onChange={permitHandleChange}
                name="permittype"
                size="small"
              >
                <MenuItem value="">
                  <em>Select Permit Type</em>
                </MenuItem>
                <MenuItem value="1 year">1 year</MenuItem>
                <MenuItem value="5 years">5 years</MenuItem>
              </Select>
            </FormControl> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Start Date</span>
                value={values.startdate ? dayjs(values.startdate) : null}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                // onChange={(date) => handleDateChange(date, "startdate")}
                onChange={handlestartDateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Expiry Date</span>
                value={values.expiredate ? dayjs(values.expiredate) : null}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                // onChange={(date) => handleDateChange(date, "expiredate")}
                onChange={handleexpireDateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <TextField //--------------------------------------Pan File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Pan"
              // onBlur={handleBlur}
              onChange={handleFileChangePan}
              value={pan}
              name="panedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                // endAdornment: (
                //   // <InputAdornment position="start">
                //   //   <UploadFileIcon
                //   //     style={{ cursor: "pointer" }}
                //   //     //onClick={() => handleIconClick()} // Corrected syntax
                //   //   />
                //   //   <input
                //   //     type="file"
                //   //     accept=".pdf"
                //   //     name="pandocument"
                //   //     //ref={fileInputRef}
                //   //     style={{
                //   //       position: "absolute",
                //   //       top: 0,
                //   //       left: 0,
                //   //       width: "100%",
                //   //       height: "100%",
                //   //       opacity: 0,
                //   //       cursor: "pointer",
                //   //       zIndex: 2,
                //   //     }}
                //   //     onChange={handleFileChangePan}
                //   //   />
                //   // </InputAdornment>
                // ),
              }}
            />

            <TextField //------------------------------------------Aadhar File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Aadhar"
              // onBlur={handleBlur}
              onChange={handleFileChangeAdhar}
              // value={attachment1}
              name="adhardocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="pandocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeAdhar}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------RC File Upload
              fullWidth
              variant="filled"
              type="text"
              label="RC"
              // onBlur={handleBlur}
              onChange={handleFileChangeRc}
              // value={attachment1}
              name="rcdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="rcdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeRc}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Fitness File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Permit"
              // onBlur={handleBlur}
              onChange={handleFileChangepermit}
              // value={attachment1}
              name="permitdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="fitnessdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeFitness}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Other File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Other Document"
              // onBlur={handleBlur}
              onChange={handleFileChangeOther}
              // value={attachment1}
              name="otherdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="otherdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeOther}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              SUBMIT
            </Button>
          </Box>
        </Box>
      </form>
      {/* Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default PermitUpdate;
