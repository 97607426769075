


import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";

const PermitTimeLine = () => {
  const { clientId } = useParams();
  console.log(clientId)
  const [permitData, setPermitData] = useState([]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get(`/getUpdatedPermitById/${clientId}`);
        const data = response.data;
        console.log( data);
        setPermitData(data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanyData();
  }, [clientId]);

  return (
    <>
    <Helmet>
      <title>ContactTab | Client Timeline</title>
    </Helmet>

    <div>
      <h2>Client Timeline</h2>
      <ul>
        {permitData.map((entry, index) => (
          <li key={index}>
            <ul>
              {Object.entries(entry).map(([key, value]) => (
                <li key={key}>
                  <strong>{key}</strong>:{" "}
                  {value.oldValue && value.newValue
                    ? `was updated from ${value.oldValue} to ${value.newValue}`
                    : JSON.stringify(value)}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  </>
  );
};

export default PermitTimeLine;





