import React, { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataContacts } from "../../../data/mockData";
import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../../assets/charts/Header";
import { useUser } from "../../../contexts/auth";

const Alluser = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (companycode !== undefined && userrole !== undefined) {
        //const response = await axios.get("/getUsers");
        const response = await axios.get("/getUsers", {
          // headers: {
          //   Authorization: `Bearer ${token}`,//Include the token for Authorization
          // },
          params: {
            companycode:companycode, // Include the companycode in the query parameters
            userrole: userrole, // Include the userrole in the query parameters
          },
        });
        console.log(response.data)
        if (response && response.data) {
          setUsers(response.data);
        }
      } 
      }
      catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchData();
  }, [companycode,userrole]);


  const handleEdit = (userId) => {
    navigate(`/update-user/${userId}`);
  };

  const handleDelete = (userId) => {
    setDeleteUserId(userId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteUser/${deleteUserId}`);
  
      if (response && response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== deleteUserId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);  
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
  
    setOpenDialog(false);
  };


  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 0.3,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      flex: 0.3,
    },
    {
      field: "age",
      headerName: "Age",
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.3,
    },
    {
      field: "number",
      headerName: "Phone Number",
      flex: 0.3,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.3,
    },
    {
      field: "joining date",
      headerName: "Joining Date",
      flex: 0.3,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 0.3,
    },
    {
      field: "companycode",
      headerName: "Company Code",
      flex: 0.3,
    },
    {
      headerName: "Actions",
      flex: 0.3,
      renderCell: (props) => buttonAlign(props),
    },
  ];

  const buttonAlign = (props) => {
    return (
      <>
        <EditIcon
          style={{ marginRight: "10px" }}
          onClick={() => handleEdit(props.row.id)}
        />
        <DeleteIcon onClick={() => handleDelete(props.row.id)} />
      </>
    );
  };
  return (
    <Box m="20px">
      <Header title="Lead List" subtitle="List of Leads..." />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>


      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this lead?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Alluser;
