// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { useState, useEffect } from "react";
// import LoggedInRoutes from "./routes/LoggedInRoutes";
// import LoggedOutRoutes from "./routes/LoggedOutRoutes";
// import "./App.css";
// import { UserProvider } from "./contexts/auth.jsx";
// function App() {
//   const [loggedIn, setLoggedIn] = useState(false);

//   useEffect(() => {
//     const checkAuthentication = async () => {
//       const token = localStorage.getItem("jwtToken");
//       console.log(token)
//       if (token) {
//         setLoggedIn(true);
//       } else {
//         setLoggedIn(false);
//       }
//     };
//     checkAuthentication();
//   }, []);

//   return (
//     <Router>
//     {loggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes /> }
//    </Router>
//   );
// }

// export default App;
import { BrowserRouter as Router, Routes, Route ,Navigate} from 'react-router-dom';
import ProtectedRoute from './contexts/ProtectedRoute';
import SignIn from "./pages/login";
import Dashboard from './components/Dashboard';
import { UserProvider } from "./contexts/auth.jsx";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import Sidebar from "./common/sidebar/sidebar.jsx";
import Topbar from "./common/topbar/topbar.jsx";
import Addestablishment from "./components/Establishment/AddEstablishment/addestablishment.jsx";
import Allestablishment from "./components/Establishment/AllEstablishment/allestablishment.jsx";
import EstablishmentUpdate from "./components/Establishment/EstablishmentUpdate/establishmentupdate.jsx";
import EstablishmentTimeLine from "./components/Establishment/EstablishmentUpdate/timeline.jsx";
import Addclient from "./components/Clients/AddClient/addclient.jsx";
import Allclient from "./components/Clients/AllClient/allclient.jsx";
import ClientUpdate from "./components/Clients/UpdateClient/updateclient.jsx";
import FitnessUpdate from "./components/Clients/UpdateClient/updatefitness.jsx";
import PermitUpdate from "./components/Clients/UpdateClient/updatepermit.jsx";
import PollutionUpdate from "./components/Clients/UpdateClient/updatepollution.jsx";
import TaxUpdate from "./components/Clients/UpdateClient/updatetax.jsx";
import ClientTimeLine from "./components/Clients/UpdateClient/clienttimeline.jsx";
import FitnessTimeLine from "./components/Clients/UpdateClient/fitnesstimeline.jsx";
import PermitTimeLine from "./components/Clients/UpdateClient/permittimeline.jsx";
import PollutionTimeLine from "./components/Clients/UpdateClient/pollutiontimeline.jsx";
import TaxTimeLine from "./components/Clients/UpdateClient/taxtimeline.jsx";
import UpdateUser from "./components/Users/UpdateUser/updateuser.jsx";
import Alluser from "./components/Users/AllUser/alluser.jsx";
import Adduser from "./components/Users/AddUser/adduser.jsx";
import UsersTimeLine from "./components/Users/UpdateUser/usertimeline.jsx";
import Addlead from "./components/Leads/AddLead/addlead.jsx";
import Alllead from "./components/Leads/AllLead/alllead.jsx";
import UpdateLead from "./components/Leads/UpdateLeads/updatelead.jsx";
import LeadsTimeLine from "./components/Leads/UpdateLeads/leadtimeline.jsx";
import AllPitched from "./components/Pitched/AllPitched/allpitched.jsx";
import UpdatePitch from "./components/Pitched/UpdatePitch/updatepitch.jsx";
import AllDemo from "./components/Demo/AllDemo/alldemo.jsx";
import UpdateDemo from "./components/Demo/UpdateDemo/updatedemo.jsx";
import AllNegotiations from "./components/Negotiations/AllNegotiation/allnegotiation.jsx";
import UpdateNegotiation from "./components/Negotiations/UpdateNegotiation/updatenegotiation.jsx";
import AllRnr from "./components/Rnr/AllRnr/allrnr.jsx";
import UpdateRnr from "./components/Rnr/UpdateRnr/updaternr.jsx";
import AllLost from "./components/ClosedLost/AllClosedLost/allclosedlost.jsx";
import UpdateLost from "./components/ClosedLost/UpdateLost/updatelost.jsx";
import AllWon from "./components/ClosedWon/AllClosedWon/allclosedwon.jsx";
import UpdateWon from "./components/ClosedWon/UpdateWon/updatewon.jsx";
import AllJunk from "./components/Junk/AllJunk/alljunk.jsx";
import UpdateJunk from "./components/Junk/UpdateJunk/updatejunk.jsx";
import { ColorModeContext, useMode } from "./common/theme/theme.jsx";
import "./App.css";
import Landing from "./components/Landing";
// import { generateToken ,messaging} from './components/Notifications/firebase.js';
// import { onMessage } from 'firebase/messaging';
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  // useEffect(()=>{
  //    generateToken();
  //    onMessage(messaging,(payload)=>{
  //     console.log(payload)
  //    })
  // },[])
  return (
    <Router>
     <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <UserProvider>
            <Sidebar isSidebar={isSidebar} />
          </UserProvider>

          <main className="content">
            <UserProvider>
              <Topbar setIsSidebar={setIsSidebar} />
            </UserProvider>

            <Routes>
              <Route
                path="/dashboard"
                element={
                  <UserProvider>
                    <Dashboard />
                  </UserProvider>
                }
              />
              <Route
                path="/add-establishment"
                element={
                  <UserProvider>
                    <Addestablishment />
                  </UserProvider>
                }
              />

              <Route
                path="/all-establishment"
                element={
                  <UserProvider>
                <Allestablishment />
                </UserProvider>
              }
              />

              <Route
                path="/update-establishment/:establishmentId"
                element={
                  <UserProvider>
                    <EstablishmentUpdate />
                  </UserProvider>
                }
              />

              <Route
                path="/establishmenttimeline/:establishmentId"
                element={
                  <UserProvider>
                    <EstablishmentTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/add-client"
                element={
                  <UserProvider>
                    <Addclient />
                  </UserProvider>
                }
              />

              <Route
                path="/all-client"
                element={
                  <UserProvider>
                <Allclient />
                </UserProvider>
              }
              />

              <Route
                path="/edit-insurance/:clientId"
                element={
                  <UserProvider>
                    <ClientUpdate />
                  </UserProvider>
                }
              />
              {/* timeline */}
              <Route
                path="/clienttimeline/:clientId"
                element={
                  <UserProvider>
                    <ClientTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/edit-fitness/:clientId"
                element={
                  <UserProvider>
                    <FitnessUpdate />
                  </UserProvider>
                }
              />

              {/*fitness timeline */}

              <Route
                path="/fitnesstimeline/:clientId"
                element={
                  <UserProvider>
                    <FitnessTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/edit-permit/:clientId"
                element={
                  <UserProvider>
                    <PermitUpdate />
                  </UserProvider>
                }
              />
              {/* permit timeline */}
              <Route
                path="/permittimeline/:clientId"
                element={
                  <UserProvider>
                    <PermitTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/edit-pollution/:clientId"
                element={
                  <UserProvider>
                    <PollutionUpdate />
                  </UserProvider>
                }
              />

              {/* pollution timeline */}
              <Route
                path="/pollutiontimeline/:clientId"
                element={
                  <UserProvider>
                    <PollutionTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/edit-tax/:clientId"
                element={
                  <UserProvider>
                    <TaxUpdate />
                  </UserProvider>
                }
              />

              {/* timeline for tax */}
              <Route
                path="/taxtimeline/:clientId"
                element={
                  <UserProvider>
                    <TaxTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/add-user"
                element={
                  <UserProvider>
                    <Adduser />
                  </UserProvider>
                }
              />

              <Route
                path="/all-user"
                element={
                  <UserProvider>
                <Alluser />
                </UserProvider>
              }
              />

              <Route
                path="/update-user/:userId"
                element={
                  <UserProvider>
                    <UpdateUser />
                  </UserProvider>
                }
              />

              {/* timeline */}
              <Route
                path="/userstimeline/:userId"
                element={
                  <UserProvider>
                    <UsersTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/add-lead"
                element={
                  <UserProvider>
                    <Addlead />
                  </UserProvider>
                }
              />

              <Route
                path="/all-lead"
                element={
                  <UserProvider>
                    <Alllead />
                  </UserProvider>}
              />

              <Route
                path="/updatelead/:lead"
                element={
                  <UserProvider>
                    <UpdateLead />
                  </UserProvider>
                }
              />

              {/* timeline for leads */}
              <Route
                path="/leadstimeline/:lead"
                element={
                  <UserProvider>
                    <LeadsTimeLine />
                  </UserProvider>
                }
              />


              <Route
                path="/all-pitched"
                element={
                <UserProvider>
                <AllPitched />
                </UserProvider>}
              />

              {/* timeline for leads */}
              {/* <Route
                path="/pitchtimeline/:pitchId"
                element={
                  <UserProvider>
                    <PitchTimeLine />
                  </UserProvider>
                }
              /> */}

              <Route
                path="/updatepitch/:pitchId"
                element={
                  <UserProvider>
                    <UpdatePitch />
                  </UserProvider>
                }
              />


              <Route
                path="/all-demo"
                element={
                  <UserProvider>
                    <AllDemo />
                  </UserProvider>}
              />
              <Route
                path="/updatedemo/:demoId"
                element={
                  <UserProvider>
                    <UpdateDemo />
                  </UserProvider>
                }
              />

              <Route
                path="/all-negotiation"
                element={
                  <UserProvider>
                <AllNegotiations />
                </UserProvider>}
              />

              <Route
                path="/updatenegotiation/:negotiationId"
                element={
                  <UserProvider>
                    <UpdateNegotiation />
                  </UserProvider>
                }
              />

              <Route
                path="/all-RNR"
                element={
                <UserProvider>
                  <AllRnr />
                </UserProvider>}
              />

              <Route
                path="/updaternr/:rnrId"
                element={
                  <UserProvider>
                    <UpdateRnr />
                  </UserProvider>
                }
              />
              <Route
                path="/all-closed-lost"
                element={
                  <UserProvider>
                <AllLost />
                </UserProvider>}
              />

              <Route
                path="/updatelost/:lostId"
                element={
                  <UserProvider>
                    <UpdateLost />
                  </UserProvider>
                }
              />
              <Route
                path="/all-closed-won"
                element={
                <UserProvider>
                <AllWon />
                </UserProvider>}
              />
              <Route
                path="/updatewon/:wonId"
                element={
                  <UserProvider>
                    <UpdateWon />
                  </UserProvider>
                }
              />

              <Route
                path="/all-junk"
                element={<UserProvider>
                  <AllJunk />
                  </UserProvider>}
              />

              <Route
                path="/updatejunk/:junkId"
                element={
                  <UserProvider>
                    <UpdateJunk />
                  </UserProvider>
                }
              />
               <Route path="/login" element={<SignIn />} />
               <Route path="/" element={<Landing />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
   </Router>
  );
}

export default App;


