
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "../../../utils/axios";
// import { useUser } from "../../../Provider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";

const UpdateUser = () => {
  const {
    userrole,
    username,
    // userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { userId } = useParams();
  const navigate = useNavigate();
  

  const [initialValues, setInitialValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    age: "",
    role: "", // Add the 'role' field here
    phoneno: "",
    address: "",
  });
  

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]);
  const [birthDate, setBirthDate] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [originalCompanyCode, setOriginalCompanyCode] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        setData(response.data);
        setFilteredData(response.data);

        // Extract establishmentIds and format them as "00001", "00002", etc.
        const formattedCodes = response.data.map((company) =>
          company.establishmentid.toString().padStart(5, "0")
        );
        // console.log(formattedCodes);
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(`/getUserById/${userId}`);
        if (response.status === 200) {
          const employeeData = response.data.employee;
          console.log(employeeData);
         
          setInitialValues({
            ...employeeData,
            role: employeeData.role, // Ensure that the key matches the one used in the form
          });
          setValues({
            ...employeeData,
            dob: employeeData.dob ? dayjs(employeeData.dob).toDate() : null,
            joiningdate: employeeData.joiningdate
              ? dayjs(employeeData.joiningdate).toDate()
              : null,
          });
          setOriginalCompanyCode(employeeData.companycode); 
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [userId]);

  // useEffect(() => {
  //   if (formData && formData.joiningdate) {
  //     setJoiningDate(new Date(formData.joiningdate));
  //   }

  //   if (formData && formData.dob) {
  //     setBirthDate(new Date(formData.dob));
  //   }

  // }, [formData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  

  const ValidationSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    // role: yup.string().required("Role is required"),
    // companyCodes: yup.string().required("Company Code is required"),
    email: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
      phoneno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
  });

  const {
    values,
    errors,
    touched,
    setValues,
    // handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formDataObject = {
          ...values,
          dob: birthDate || null, // Set to null if empty
          joiningdate: joiningDate || null,
          userrole: userrole,
          companycode: originalCompanyCode,
          username: username,
        };
        console.log(formDataObject);
        const response = await axios.post(`/updateUserById/${userId}`, {
          data: JSON.stringify(formDataObject),
        });

        if (response.status === 201) {
          // Update state with success message
          setSuccessMessage("Employee Update successful");
          setOpenSuccess(true);

          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }

        // Handle the error by updating state or showing an error message
      }
    },
  });

  // const handleUpdate = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const formDataObject = {
  //       ...formData,
  //       dob: birthDate || null, // Set to null if empty
  //       joiningdate: joiningDate || null,
  //       userrole: userrole,
  //       companycode: companycode,
  //       username: username,
  //     };

  //     const response = await axios.post(`/updateUserById/${userId}`, {
  //       data: JSON.stringify(formDataObject)
  //     });

  //     if (response.status === 201) {
  //       // Update state with success message
  //       setSuccessMessage("Employee Update successful");
  //       setOpenSuccess(true);

  //       // Hide the success message after 3 seconds (adjust duration as needed)
  //       setTimeout(() => {
  //         setOpenSuccess(false);
  //         setSuccessMessage("");
  //       }, 3000);

  //       // Clear form data after successful submission
  //       setFormData({
  //         firstname: "",
  //         lastname: "",
  //         email: "",

  //         age: "",
  //         role: "",
  //         phoneno: "",

  //         address: "",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);

  //     // Handle specific error cases
  //     if (error.response) {
  //       console.error("Response data:", error.response.data);
  //       console.error("Response status:", error.response.status);
  //     }

  //     // Handle the error by updating state or showing an error message
  //   }
  // };

  const selectDate = (sd) => {
    setBirthDate(sd);
  };

  const selectJoiningDate = (sd) => {
    setJoiningDate(sd);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
        <Button
            onClick={() => navigate(`/userstimeline/${userId}`)}
            variant="contained"
          >
            Timeline
          </Button>
          <Header
            title="Add Lead"
            subtitle="Create a New Lead Profile"
          />
          <h3>User Details</h3>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="First Name"
              onChange={handleChange}
              value={values.firstname}
              name="firstname"
              error={!!touched.firstname && !!errors.firstname}
              helperText={touched.firstname && errors.firstname}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Last Name"
              onChange={handleChange}
              value={values.lastname}
              name="lastname"
              // error={!!touched.lastname && !!errors.lastname}
              // helperText={touched.lastname && errors.lastname}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              // onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={!!touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Phone Number"
              // onBlur={handleBlur}
              onChange={handleChange}
              value={values.phoneno}
              name="phoneno"
              error={!!touched.phoneno && !!errors.phoneno}
              helperText={touched.phoneno && errors.phoneno}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              // onBlur={handleBlur}
              onChange={handleChange}
              value={values.address}
              name="address"
              // error={!!touched.address && !!errors.address}
              // helperText={touched.address && errors.address}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Age"
              // onBlur={handleBlur}
              onChange={handleChange}
              value={values.age}
              name="age"
              // error={!!touched.age && !!errors.age}
              // helperText={touched.age && errors.age}
              sx={{ gridColumn: "span 2" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                label=<span> Date Of Birth </span>
                value={dayjs(birthDate)}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                onChange={(e) => selectDate(e["$d"])}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span> Joining Date </span>
                value={dayjs(joiningDate)}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                onChange={(e) => selectJoiningDate(e["$d"])}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <FormControl
              fullWidth
              variant="filled"
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="role">Role</InputLabel>
              <Select
                labelId="Role"
                id="role"
                // onBlur={handleBlur}
                onChange={handleChange}
                value={values.role}
                name="role"
                
              >
                <MenuItem value="Superadmin">Super Admin</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="Employee">Employee</MenuItem>
              </Select>
              {/* {touched.role && errors.role && (
                  <div style={{ color: "red" }}>{errors.role}</div>
                )} */}
            </FormControl>

            <FormControl
              fullWidth
              variant="filled"
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="companyCode-label">Company Code</InputLabel>

              <Select
                labelId="companyCode-label"
                id="companyCodes"
                onChange={handleChange}
                // value={formData.companyCodes}
                value={
                  values.companyCodes ||
                  (companyCodes.length > 0 ? values.companycode : "")
                }
                name="companyCodes"
                label="Company Code"
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value="">Select Company Code</MenuItem>
                {companyCodes.map((code, index) => (
                  <MenuItem
                    key={index}
                    value={code}
                  >
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            mt="20px"
          >
            <Button
              type="submit"
              color="secondary"
              variant="contained"
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default UpdateUser;
