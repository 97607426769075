import {
  Box,
  Badge,
  IconButton,
  useTheme,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
} from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useUser } from "../../contexts/auth";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  console.log(colorMode)
  const [modalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate(); // Use useNavigate
  const handleAction = () => {
    // No action needed, simply close the modal
    closeModal();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get("/notifications");

      if (!response.data) {
        throw new Error("No data received");
      }

      const data = response.data;
      console.log("Fetched data:", data);
      setNotifications(data.notifications);
      setNewNotifications(
        data.notifications.some((notification) =>
          isNewNotification(notification)
        )
      );
    } catch (error) {
      console.error("Error fetching notifications:", error);

      // Log the entire response to inspect its content
      if (error.response && error.response.data) {
        // console.log("Raw Response:", error.response.data);
      }
    }
  };

  const isNewNotification = (notification) => {
    const notificationDate = new Date(notification.expiredate);
    const currentDate = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    return (
      notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds
    );
  };

  useEffect(() => {
    fetchNotifications();
  }, []); // Add notifications to the dependency array to log when it changes

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLogout = async () => {
    // Prompt the user to confirm logout
    const confirmed = window.confirm("Do you want to log out?");
    if (confirmed) {
      try {
        // Call the logout API endpoint
        const response = await axios.post("/logout");

        // Check if the logout was successful
        if (response.status === 200) {
          // Remove JWT token from localStorage
          localStorage.removeItem("jwtToken");
          // Redirect the user to the homepage after logout
          window.location.reload();
          navigate("/login");
        }
      } catch (error) {
        // Handle errors, such as token expiration or invalid token
        if (error.response.status === 403) {
          alert("Token Expired. Please login again.");
          localStorage.removeItem("jwtToken");
          window.location.reload();
          navigate("/login");
        } else if (error.response.status === 401) {
          alert("Invalid token. Please login again.");
          localStorage.removeItem("jwtToken");
          window.location.reload();
          navigate("/login");
        } else {
          alert("Error: " + error.response.data.error);
        }
      }
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
      <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'light' ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
        </IconButton>
        <IconButton onClick={openModal}>
          <Badge
            badgeContent={notifications.length}
            color="error"
            variant="dot"
          >
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleMenuOpen}>
          <PersonOutlinedIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 500,
            borderRadius: 3,
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: 0,
              right: 5,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" mb={2}>
            Reminders
          </Typography>
          <Divider sx={{ mb: 2 }} />{" "}
          {/* Add a divider for better visual separation */}
          <List>
            {notifications.map((notification, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${notification.ownername}, Your ${
                    notification.sourcetable
                  } expiry date is on: ${new Date(
                    notification.expiredate
                  ).toDateString()}`}
                />
              </ListItem>
            ))}
          </List>
          {/* Add a button for further action */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleAction}>
              Take Action
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Topbar;
