
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import * as Yup from "yup"; // Import Yup for validation
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "../../../utils/axios";

import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";
 import { useUser } from "../../../contexts/auth";


const ClientUpdate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { clientId } = useParams();
  console.log(clientId);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ownername: "",
    address: "",
    guardianname: "",
    vehicleno: "",
    phoneno: "",
    registrationno: "",
    registrationdate: null,
    ncbcd: "",
    engineno: "",
    chassisno: "",
    odpremium: "",
    netpremium: "",
    finalpremium: "",
    policyno: "",
    idvvalue: "",
    macmodule: "",
    startdate: null,
    expiredate: null,
    penality: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [datas, setData] = useState({});
  const [adhardocument, setAdhardocument] = useState(null);
  const [pan, setPan] = useState(null);
  const [Rc, setRc] = useState(null);
  const [other, setOther] = useState(null);
  const [insurance, setInsurance] = useState(null);

  const handleFileChangeAdhar = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAdhardocument(selectedFiles);
      handleInputChange({
        target: {
          name: "adhardocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangePan = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPan(selectedFiles);
      handleInputChange({
        target: {
          name: "pandocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeRc = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setRc(selectedFiles);
      handleInputChange({
        target: {
          name: "rcdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeOther = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setOther(selectedFiles);
      handleInputChange({
        target: { 
          name: "otherdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeinsurance = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setInsurance(selectedFiles);
      handleInputChange({
        target: {
          name: "insurancedocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };


  // console.log(datas);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleregistrationdateChange = (date) => {
    let formattedDate = null;

    if (date) {
      formattedDate = dayjs(date).isValid() ? dayjs(date).toISOString() : null;
    }

    setFormData({
      ...formData,
      registrationdate: formattedDate,
    });
  };

  const handlestartDateChange = (date) => {
    let formattedstartDate = null;

    if (date) {
      formattedstartDate = dayjs(date).isValid()
        ? dayjs(date).toISOString()
        : null;
    }

    setFormData({
      ...formData,
      startdate: formattedstartDate,
    });
  };

  const handleexpireDateChange = (date) => {
    let formattedexpireDate = null;

    if (date) {
      formattedexpireDate = dayjs(date).isValid()
        ? dayjs(date).toISOString()
        : null;
    }

    setFormData({
      ...formData,
      expiredate: formattedexpireDate,
    });
  };

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

//files
  const loadinsurance = async () => {
    const res = await axios.get(`/getinsurance/${clientId}`, {});
    setData(res.data.client);
  };


  //data
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`/getInsurance/${clientId}`);
        if (response.status === 200) {
          const clientData = response.data.client;
          setFormData({
           
            ...clientData,
            registrationdate: clientData.registrationdate
              ? dayjs(clientData.registrationdate).toDate()
              : null,
            startdate: clientData.startdate
              ? dayjs(clientData.startdate).toDate()
              : null,
            expiredate: clientData.expiredate
              ? dayjs(clientData.expiredate).toDate()
              : null,
            insurencedocument: clientData.insurencedocument || "",
          });

          setValues({
            ...clientData,
           
          });
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientId) {
    
      fetchClientData();
      loadinsurance();
    }
  }, [clientId]);

  const handleEditClick = () => {
    setIsEditMode(true); // Enable edit mode
    setIsSubmitting(false); // Reset submission status on edit click
  };

  const ValidationSchemaInsurance = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    // vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    // registrationno: Yup.string().required(
    //   "Please enter the Registration Number"
    // ),
    // ncb: Yup.string().required("Please enter the NCB "),
    // cd: Yup.string().required("Please enter the CD"),
    // engineno: Yup.string().required("Please enter the Engine Number"),
    // chassisno: Yup.string().required("Please enter the Chassis Number"),
    // odpremium: Yup.string().required("Please enter the OD premium value"),
    // netpremium: Yup.string().required("Please enter the Net premium value"),
    // finalpremium: Yup.string().required(
    //   "Please enter the Final Premium value "
    // ),
    // policyno: Yup.string().required("Please enter the Policy Number"),
    // idvvalue: Yup.string().required("Please enter the IDV value"),
    // macmodule: Yup.string().required("Please enter the Vehicle Number"),
  });






  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    validationSchema: ValidationSchemaInsurance,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      try {
        const formDataToSend = new FormData();
  
        formDataToSend.append("formData", JSON.stringify(values));
        console.log(JSON.stringify(formData));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("insurancedocument", insurance);
        formDataToSend.append("username", username);
  
        console.log(adhardocument);
        console.log(pan);
        const response = await axios.post(
          `/updateInsurance/${clientId}`,
          formDataToSend
        );
        if (response.status === 200) {
          setSuccessMessage("Client updated successfully");
          setOpenSuccess(true);
          setIsEditMode(false);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error updating client data:", error.response.data);
        setIsSubmitting(false);
      }
    },
  });


  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   try {
  //     const formDataToSend = new FormData();

  //     formDataToSend.append("formData", JSON.stringify(formData));
  //     console.log(JSON.stringify(formData));
  //     formDataToSend.append("pandocument", pan);
  //     formDataToSend.append("adhardocument", adhardocument);
  //     formDataToSend.append("rcdocument", Rc);
  //     formDataToSend.append("otherdocument", other);
  //     formDataToSend.append("insurancedocument", insurance);
  //     formDataToSend.append("username", username);

  //     console.log(adhardocument);
  //     console.log(pan);
  //     const response = await axios.post(
  //       `/updateInsurance/${clientId}`,
  //       formDataToSend
  //     );
  //     if (response.status === 200) {
  //       setSuccessMessage("Client updated successfully");
  //       setOpenSuccess(true);
  //       setIsEditMode(false);
  //       setIsSubmitting(false);
  //     }
  //   } catch (error) {
  //     console.error("Error updating client data:", error.response.data);
  //     setIsSubmitting(false);
  //   }
  // };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
        <Button
            onClick={() => navigate(`/clienttimeline/${clientId}`)}
            variant="contained"
          >
            Timeline
          </Button>
          <Header
            title="Insurance Details Update"
            subtitle="Update your Insurance details"
          />
          <Typography variant="h6" align="left">
            Service Types: <b>Insurance</b>
          </Typography>
          <br></br>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Owner Name"
              onChange={handleChange}
              value={values.ownername}
              name="ownername"
              size="small"
              error={touched.ownername && !!errors.ownername}
                helperText={
                  touched.ownername && errors.ownername
                    ? "owner name is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onChange={handleChange}
              value={values.address}
              name="address"
              size="small"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Guardian Name"
              onChange={handleChange}
              value={values.guardianname}
              name="guardianname"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Vechile Number"
              onChange={handleChange}
              value={values.vehicleno}
              name="vehicleno"
              error={touched.vehicleno && !!errors.vehicleno}
                helperText={
                  touched.vehicleno && errors.vehicleno
                    ? "vehicle no is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Phone Number"
              onChange={handleChange}
              value={values.phoneno}
              name="phoneno"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Registration Number"
              onChange={handleChange}
              value={values.registrationno}
              name="registrationno"
              error={touched.registrationno && !!errors.registrationno}
                helperText={
                  touched.registrationno && errors.registrationno
                    ? "registration no is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Registration Date</span>
                value={
                  formData.registrationdate
                    ? dayjs(values.registrationdate)
                    : null
                }
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                // onChange={(date) =>
                //   handleDateChange(date, "registrationdate")
                // }
                onChange={handleregistrationdateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="NCB"
              onChange={handleChange}
              value={values.ncb}
              name="ncb"
              error={touched.ncb && !!errors.ncb}
                helperText={
                  touched.ncb && errors.ncb
                    ? "ncb no is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="CD"
              onChange={handleChange}
              value={values.cd}
              name="cd"
              error={touched.cd && !!errors.cd}
                helperText={
                  touched.cd && errors.cd
                    ? "cd no is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Engine no"
              onChange={handleChange}
              value={values.engineno}
              name="engineno"
              error={touched.engineno && !!errors.engineno}
                helperText={
                  touched.engineno && errors.engineno
                    ? "engine no is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Chanssis Number"
              onChange={handleChange}
              value={values.chassisno}
              name="chassisno"
              error={touched.chassisno && !!errors.chassisno}
              helperText={
                touched.chassisno && errors.chassisno
                  ? "chassis  no is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            {/* insurance od premium */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="OD Premium"
              onChange={handleChange}
              value={values.odpremium}
              name="odpremium"
              error={touched.odpremium && !!errors.odpremium}
                helperText={
                  touched.odpremium && errors.odpremium
                    ? "od premium no is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />

            {/* insurance net premium */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Net Premium"
              onChange={handleChange}
              value={values.netpremium}
              name="netpremium"
              error={touched.netpremium && !!errors.netpremium}
                helperText={
                  touched.netpremium && errors.netpremium
                    ? "netpremium is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />

            {/* insurance final premium */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Final Premium"
              onChange={handleChange}
              value={values.finalpremium}
              name="finalpremium"
              error={touched.finalpremium && !!errors.finalpremium}
              helperText={
                touched.finalpremium && errors.finalpremium
                  ? "final premium is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            {/* insurance policy no */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Policy Number"
              onChange={handleChange}
              value={values.policyno}
              name="policyno"
              error={touched.policyno && !!errors.policyno}
              helperText={
                touched.policyno && errors.policyno
                  ? "policy  no is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            {/* insurance idv value */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="IDV Value"
              onChange={handleChange}
              value={values.idvvalue}
              name="idvvalue"
              error={touched.idvvalue && !!errors.idvvalue}
                helperText={
                  touched.idvvalue && errors.idvvalue
                    ? "idv value  is required"
                    : ""
                }
              sx={{ gridColumn: "span 2" }}
            />

            {/* insurance mac module */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Make Module"
              onChange={handleChange}
              value={values.macmodule}
              name="macmodule"
              error={touched.macmodule && !!errors.macmodule}
              helperText={
                touched.macmodule && errors.macmodule
                  ? "mac module no is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            {/* insurance penality */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Penality"
              onChange={handleChange}
              value={values.penality}
              name="penality"
              error={touched.penality && !!errors.penality}
              helperText={
                touched.penality && errors.penality
                  ? "penality is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Start Date</span>
                value={values.startdate ? dayjs(values.startdate) : null}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                //
                onChange={handlestartDateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Expiry Date</span>
                value={values.expiredate ? dayjs(values.expiredate) : null}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                onChange={handleexpireDateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <TextField //--------------------------------------Pan File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Pan"
              // onBlur={handleBlur}
              onChange={handleFileChangePan}
              value={datas.pancard}
              name="panedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                // endAdornment: (
                //   // <InputAdornment position="start">
                //   //   <UploadFileIcon
                //   //     style={{ cursor: "pointer" }}
                //   //     //onClick={() => handleIconClick()} // Corrected syntax
                //   //   />
                //   //   <input
                //   //     type="file"
                //   //     accept=".pdf"
                //   //     name="pandocument"
                //   //     //ref={fileInputRef}
                //   //     style={{
                //   //       position: "absolute",
                //   //       top: 0,
                //   //       left: 0,
                //   //       width: "100%",
                //   //       height: "100%",
                //   //       opacity: 0,
                //   //       cursor: "pointer",
                //   //       zIndex: 2,
                //   //     }}
                //   //     onChange={handleFileChangePan}
                //   //   />
                //   // </InputAdornment>
                // ),
              }}
            />
            <TextField //------------------------------------------Aadhar File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Aadhar"
              // onBlur={handleBlur}
              onChange={handleFileChangeAdhar}
              value={datas.adharcard}
              name="adhardocumentx"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="pandocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeAdhar}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------RC File Upload
              fullWidth
              variant="filled"
              type="text"
              label="RC"
              // onBlur={handleBlur}
              onChange={handleFileChangeRc}
              value={datas.rc}
              name="rcdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="rcdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeRc}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Insurance File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Insurance"
              // onBlur={handleBlur}
              onChange={handleFileChangeinsurance}
              value={datas.insurance}
              name="insurancedocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="fitnessdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeFitness}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Other File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Other Document"
              // onBlur={handleBlur}
              onChange={handleFileChangeOther}
              value={datas.other}
              name="otherdocument"
              sx={{ gridColumn: "span 4" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="otherdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeOther}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              SUBMIT
            </Button>
          </Box>
        </Box>  
      </form>
      {/* Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default ClientUpdate;
