import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  InputAdornment,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Person2Icon from "@mui/icons-material/Person2";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import Logo from "../../Assets/img/logo.png";
import Logo from "../../assets/images/logo.png";
// import Image from "../../Assets/img/bg-image-img.png";
// import Image from "../../asset/illustration.png";
import { useNavigate } from "react-router-dom";

// import { Helmet } from "react-helmet";

const defaultTheme = createTheme();

const SignIn = () => {
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
 // Check if the user is already logged in, and perform logout action
 useEffect(() => {
  const jwtToken = localStorage.getItem("jwtToken");
  if (jwtToken) {
    // Perform logout action
    localStorage.removeItem("jwtToken");
    setLoginStatus("");
    navigate("/login");
    
  }
}, []);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // const handleLoginSuccess = (token) => {
  //   // Save the token to session storage
  //   localStorage.setItem("jwtToken", token);

  //   // Redirect to the dashboard or another protected route
  //   navigate("/dashboard");
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("/login", {
        email: formData.email,
        password: formData.password,
      });

      if (response.status === 200) {
         setLoginStatus(response.data.success);
        // Assuming the server responds with a status code of 200 for successful login
         localStorage.setItem("jwtToken", response.data.token);
        // handleLoginSuccess(response.data.token);
         navigate("/dashboard");
         window.location.reload();
      } else {
       
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error(error);

      if (error.response) {
        if (error.response.status === 401) {
          setError("Invalid Email or Password.");
        } else {
          setError("Something went wrong. Please try again.");
        }
      } else {
        setError("Network error. Please try again.");
      }
    }
  };

  // // Check if the user is already authenticated
  // useEffect(() => {
  //   const checkAuthentication = async () => {
  //     try {
  //       const jwtToken = localStorage.getItem("jwtToken");

  //       if (jwtToken) {
  //         // Validate the token on the server
  //         const response = await axios.post("/loginuser", {
  //           token: jwtToken,
  //         });

  //         if (response.status === 200 && response.data.valid) {
  //           // Token is valid, redirect to the dashboard
  //           navigate("/dashboard");
  //         } else {
  //           // Token is invalid, redirect to the login page
  //           navigate("/login");
  //         }
  //       } else {
  //         // No token found, redirect to the login page
  //         navigate("/login");
  //       }
  //     } catch (error) {
  //       console.error("Error during token validation:", error);
  //       // Handle error (e.g., redirect to login)
  //       navigate("/login");
  //     }
  //   };

  //   // Call the function to check authentication
  //   checkAuthentication();
  // }, [navigate]);

  return (
    <>
      {/* <Helmet>
        <title>Contact Tab Login</title>
      </Helmet> */}
      {/* <LoginLayout> */}
      <ThemeProvider theme={defaultTheme}>
        <Grid item container component="main" maxWidth="xs">
          <CssBaseline />
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
              <img src={Logo} style={{ height: "7rem" }} alt="Logo" />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 5rem",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#F9822B" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  placeholder="Enter Your Email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person2Icon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleTogglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                />

                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    align="center"
                    gutterBottom
                  >
                    {error}
                  </Typography>
                )}

                <FormControlLabel
                  sx={{ display: "block" }}
                  control={
                    <Checkbox
                      value={rememberMe}
                      color="primary"
                      onChange={handleRememberMeChange}
                    />
                  }
                  label="Remember me"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ backgroundColor: "#F9822B", mt: 2 }}
                  >
                    Sign In
                  </Button>
                </Box>
                <Grid item container>
                  <Grid item xs>
                    <Link
                      href="#"
                      variant="body2"
                      sx={{
                        display: "block",
                        textAlign: "center",
                        mt: 2,
                        color: "#F9822B",
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box
              sx={{
                height: "100vh",
                background: "#F9822B",
                borderTopLeftRadius: "5rem",
                borderBottomLeftRadius: "5rem",
                padding: "3rem",
                color: "white",
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Welcome <span>to</span> Admin Login Page
              </Typography>
              <Typography variant="body1" gutterBottom>
                Login to access your portal
              </Typography>
              {/* <img
                src={Image}
                style={{ width: "100%" }}
                alt="Background"
              /> */}
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      {/* </LoginLayout> */}
    </>
  );
};
export default SignIn;
