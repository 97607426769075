import axios from "./../utils/axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("jwtToken");
  console.log(token)
  const [isAuthRole, setIsAuthRole] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [userrole, setUserRole] = useState();
  const [loggedIn, setLoggedIn] = useState(false); // Set initial loggedIn state to false
  const [username, setUserName] = useState();
  const [userid, setUserId] = useState();
  const [companycode, setCompanycode] = useState();
console.log(loggedIn)
  const loadAllDetails = async () => {
    try {
      const response = await axios.post("/loginuser", null, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        const { fname, role1, uid, companycode } = response.data.user;
        setUserName(fname);
        setUserRole(role1);
        setUserId(uid);
        setCompanycode(companycode);
        setLoggedIn(true); // Set loggedIn to true after successful login
        const authorizationResult = role1 === "admin";
        setIsAuthRole(authorizationResult);

        const authorizationResults = companycode === 1 && role1 === "superadmin";
        setIsAuthorized(authorizationResults);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error:", error);

      if (error.response && error.response.status === 403) {
        alert("Token Expired. Please login again.");
        localStorage.removeItem("jwtToken");
        navigate("/");
      } else if (error.response && error.response.status === 401) {
        alert("Invalid token. Please login again.");
        localStorage.removeItem("jwtToken");
        navigate("/");
      } else {
        alert(
          "Error: " +
            (error.response ? error.response.data.error : error.message)
        );
      }
    }
  };

  useEffect(() => {
    if (token) {
      // Only load user details if token exists
      loadAllDetails();
      // Set loggedIn to true when token exists
     
    } else {
      // Redirect to login if token does not exist
      navigate("/");
    }
  }, []);
  

  // Provide the user context with the user data
  const userContextValue = {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
    loggedIn,
    setLoggedIn,
  };

  return (
    <UserContext.Provider value={userContextValue}>
     {token && children}
    </UserContext.Provider>
  );
};

