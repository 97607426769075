
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "../../../utils/axios";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { useUser } from "../../../Provider";
import { useParams } from "react-router-dom";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";


const UpdateDemo = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { demoId } = useParams();
  console.log(demoId)
;
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();


  const navigate = useNavigate();

  const [demoData, setDemoData] = useState({
    leadname: "",
    contactno: "",
    leademail: "",
    leadlocation: "",
    leadsource: "",
    leadcomments: "",
    leadstate: "",
    status: "active",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

 
 useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await axios.get(`/getDemoById/${demoId}`);
       
        const demoData = response.data;
        if (response && response.data) {
          setDemoData({
            
           ...demoData,
          });
          setValues({
            ...demoData,
           
          });
          
        } else {
          // Handle error for failed request
        }
      } catch (error) {
        // Handle network errors
      }
    };

    fetchLeadData();
  }, [demoId]);


 

  

  const ValidationSchema = yup.object().shape({
    leadname: yup.string().required("Lead name is required"),
    contactno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Phone Number is required"),
    leademail: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
  });

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: demoData, // Use demoData as initialValues
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      try {
        const demoDataWithUsername = {
          ...values, // Use values instead of demoData here
          username: username,
        };
        const response = await axios.put(
          `/updateDemoById/${demoId}`,
          demoDataWithUsername
        );
  
        if (response.status === 200) {
          setSubmitMessage("Lead data updated successfully!");
          setOpenSnackbar(true);
  
          setTimeout(() => {
            setOpenSnackbar(false);
            setSubmitMessage("");
          }, 3000);
        } else {
          // Handle error for failed request
        }
      } catch (error) {
        // Handle network errors
      }
    },
  });
  


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const demoDataWithUsername = {
  //       ...demoData,
  //       username: username,
  //     };
  //     const response = await axios.put(
  //       `/updateDemoById/${demoId}`,
  //       demoDataWithUsername
  //     );

  //     if (response.status === 200) {
  //       setSubmitMessage("Lead data updated successfully!");
  //       setOpenSnackbar(true);

  //       setTimeout(() => {
  //         setOpenSnackbar(false);
  //         setSubmitMessage("");
  //       }, 3000);
    
  //       // setEditMode(false);
  //     } else {
  //       // Handle error for failed request
  //     }
  //   } catch (error) {
  //     // Handle network errors
  //   }
  // };

  // const handleCloseSnackbar = () => {
  //   setOpenSnackbar(false);
  // };
const lead = demoId;
  return (
    
        <form onSubmit={handleSubmit}>
          <Box m="20px">
          <Button
            onClick={() => navigate(`/leadstimeline/${lead}`)}
            variant="contained"
          >
            Timeline
          </Button>
            <Header title="Add Lead" subtitle="Create a New Lead Profile" />
            <h3>Lead Details</h3>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              onChange={handleChange}
              value={values.leadname}
              name="leadname"
              error={!!touched.leadname && !!errors.leadname}
              helperText={touched.leadname && errors.leadname}
              sx={{ gridColumn: "span 2" }}
            />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
              
                onChange={handleChange}
                value={values.contactno}
                name="contactno"
                error={!!touched.contactno && !!errors.contactno}
                helperText={touched.contactno && errors.contactno}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
              
                onChange={handleChange}
                value={values.leademail}
                name="leademail"
                error={!!touched.leademail && !!errors.leademail}
                helperText={touched.leademail && errors.leademail}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Location"
              
                onChange={handleChange}
                value={values.leadlocation}
                name="leadlocation"
                // error={!!touched.leadLocation && !!errors.leadLocation}
                // helperText={touched.leadLocation && errors.leadLocation}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Lead Source"
                
                onChange={handleChange}
                value={values.leadsource}
                name="leadsource"
                // error={!!touched.leadSource && !!errors.leadSource}
                // helperText={touched.leadSource && errors.leadSource}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Comment"
                
                onChange={handleChange}
                value={values.leadcomments}
                name="leadcomments"
                // error={!!touched.leadComments && !!errors.leadComments}
                // helperText={touched.leadComments && errors.leadComments}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                SUBMIT
              </Button>
            </Box>
          </Box>
        </form>
   
   
  );
};



export default UpdateDemo;
