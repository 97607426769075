



import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataContacts } from "../../../data/mockData";

import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import axios from "../../../utils/axios";

import { useNavigate } from "react-router-dom";
import Header from "../../../assets/charts/Header";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const AllLost = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [losts, setLosts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLostId, setDeleteLostId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get("/getLost");
      if (response && response.data) {
        setLosts(response.data);
        console.log(response.data)
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  
 const handleStatusChange = async (event, lostId) => {
    try {
      const updateLost = losts.map((lost) =>
        lost.id === lostId
          ? { ...lost, leadType: event.target.value }
          : lost
      );
      setLosts(updateLost);

      const response = await axios.put(
        `/updateLostState/${lostId}`,
        {
          leadType: event.target.value,
        }
      );

      if (response && response.status === 200) {
        fetchData();
        setDeleteSuccessMessage("Lead status updated successfully!");
      } else {
        console.error("Failed to update lead status");
      }
    } catch (error) {
      console.error("Error updating lead status:", error);
    }
  };
  

  const handleEdit = (lost) => {
    console.log(lost);
    navigate(`/updatelost/${lost}`);
  };
  

  const handleDelete = (lostId) => {
    setDeleteLostId(lostId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteLost/${deleteLostId}`);

      if (response && response.status === 200) {
        setLosts((prevLosts) =>
          prevLosts.filter((lost) => lost.id !== deleteLostId)
        );
        setDeleteSuccessMessage("Lost lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lost lead");
      }
    } catch (error) {
      console.error("Error deleting lost lead:", error);
    }

    setOpenDialog(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    {
      field: "leadname",
      headerName: "Lead Name",
      flex: 0.3,
    },
    {
      field: "leademail",
      headerName: "Email",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "contactno",
      headerName: "Contact Number",
      flex: 0.3,
    },
    {
      field: "leadsource",
      headerName: "Source",
      flex: 0.3,
    },
    {
      field: "leadcomments",
      headerName: "Comments",
      flex: 0.3,
    },
    {
      field: "leadstate",
      headerName: "State",
      flex: 0.3,
      renderCell: (params) => (
        <span>{params.row.leadstate}</span>
      ),
    },
  
    {
      field: "leadType",
      headerName: "Lead State",
      flex: 0.3,
      renderCell: (params) => (
        <Select
          className="select-menu"
          value={params.row.leadType}
          onChange={(event) => handleStatusChange(event, params.row.id)}
        >
          <MenuItem value="Pitched">Pitched</MenuItem>
          <MenuItem value="Negotiation">Negotiation</MenuItem>
          <MenuItem value="Demo">Demo</MenuItem>
          <MenuItem value="Closed Lost">Closed Lost</MenuItem>
          <MenuItem value="Closed Won">Closed Won</MenuItem>
          <MenuItem value="RNR">RNR</MenuItem>
          <MenuItem value="Junk">Junk</MenuItem>
        </Select>
      ),
    },
    {
      headerName: "Actions",
      flex: 0.3,
      renderCell: (props) => (
        <>
      <EditIcon
        style={{ marginRight: "10px" }}
        onClick={() => {
          console.log(props.row.id); // Print id to the console
          handleEdit(props.row.id);
        }}
      />
      <DeleteIcon onClick={() => handleDelete(props.row.id)} />
    </>
      ),
    },
  ];



 

  

 
 


  return (
    <Box m="20px">
      <Header title="Lead List" subtitle="List of Leads..." />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={losts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this lost lead?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllLost;




















































