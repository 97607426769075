



import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";

const PollutionTimeLine = () => {
  const { clientId } = useParams();
  console.log(clientId)
  const [pollutionData, setPollutionData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get(`/getUpdatedPollutionById/${clientId}`);
        const data = response.data;
        // console.log( data);
        setPollutionData(data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanyData();
  }, [clientId]);

  return (
    <>
      <Helmet>
        <title>ContactTab | User Timeline</title>
      </Helmet>

      <div>
        {pollutionData ? (
          <div>
            <h2>User Timeline</h2>
            <ul>
              {Object.entries(pollutionData).map(([fieldName, value]) => (
                <li key={fieldName}>
                  {typeof value === 'object' && value !== null ? (
                    <>
                      {/* {fieldName}: */}
                      <ul>
                        <li>
                        {fieldName}  was updated from {value.oldValue !== undefined ? value.oldValue : 'blank'} to {value.newValue !== undefined ? value.newValue : 'blank'}
                        </li>
                      </ul>
                    </>
                  ) : (
                    `${fieldName} was updated from blank value to : ${value}`

                    
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  );
};

export default PollutionTimeLine;





