import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import axios from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";
// import { useUser } from "../../../Provider";

const Addlead = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  const navigate = useNavigate();
  const initialValues = {
    leadName: "",
    contactNo: "",
    leadEmail: "",
    leadLocation: "",
    leadSource: "",
    leadComments: "",
    status: "active",
    companyCodes:userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };
  const [companyCodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        console.log(response.data)
        const formattedCodes = response.data.map((company) =>
          company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const ValidationSchema = yup.object().shape({
    leadName: yup.string().required("Lead name is required"),
    contactNo: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Phone Number is required"),
    leadEmail: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };

        const response = await axios.post("/addLeads", leadDataWithUsername);

        if (response.status === 201) {
          // Update state with success message
          setSuccessMessage("Lead data added successful");
          setOpenSuccess(true);
         // sendMsg();
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
const header ={
  headers:{
    Authorization: `Bearer EAAMVy5OZB2W4BO3xMiEuhNtYZBY4FqGfk9GGDOXTZCSAmj3V4kr12mUdM0aWTrzPZBYyD6F5dXhifyigaLFm5YTETOcSlgKm8hdF6bRh3F51qNLZAL7D91HGkKtvh6O2biCBbqyX2qrcsdPm9ELHltqNsZADOOFvDwJXYZBABR6i5FSuRZBRf8XZAZB9ZBRoqhnN4GZB`,
    Accept:`application/json`
  }
}
  const sendMsg =()=>{
    const body ={
      "messaging_product":"whatsapp",
      "to":"91"+values.contactNo,
      "type":"template",
      "template":{
        "name":"notification",
        "language":{
          "code":"en"
        }
      }
    }
    axios.post('https://graph.facebook.com/v18.0/217459234774748/messages', body, header)
    .then((res)=>(
      console.log("msg sent success",res)
    ))
    .catch((err)=>(
      console.log(err)
    ))
  }

  
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <Header
            title="Add Lead"
            subtitle="Create a New Lead Profile"
          />
          <h3>Lead Details</h3>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              onChange={handleChange}
              value={values.leadName}
              name="leadName"
              error={touched.leadName && !!errors.leadName} // Show error style if touched and error exists
              helperText={
                touched.leadName && errors.leadName
                  ? "leadName is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Phone Number"
              onChange={handleChange}
              value={values.contactNo}
              name="contactNo"
              error={touched.contactNo && !!errors.contactNo} // Show error style if touched and error exists
              helperText={
                touched.contactNo && errors.contactNo
                  ? "contactNo is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              onChange={handleChange}
              value={values.leadEmail}
              name="leadEmail"
              error={touched.leadEmail && !!errors.leadEmail} // Show error style if touched and error exists
              helperText={
                touched.leadEmail && errors.leadEmail
                  ? "leadEmail is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Location"
              onChange={handleChange}
              value={values.leadLocation}
              name="leadLocation"
              // error={!!touched.leadLocation && !!errors.leadLocation}
              // helperText={touched.leadLocation && errors.leadLocation}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Lead Source"
              onChange={handleChange}
              value={values.leadSource}
              name="leadSource"
              // error={!!touched.leadSource && !!errors.leadSource}
              // helperText={touched.leadSource && errors.leadSource}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Comment"
              onChange={handleChange}
              value={values.leadComments}
              name="leadComments"
              // error={!!touched.leadComments && !!errors.leadComments}
              // helperText={touched.leadComments && errors.leadComments}
              sx={{ gridColumn: "span 2" }}
            />
             {userrole === "Superadmin" && companycode === 1 ? (
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="companyCode-label">Company Code</InputLabel>
                <Select
                  labelId="companyCode-label"
                  id="companyCodes"
                  onChange={handleChange}
                  value={values.companyCodes}
                  name="companyCodes"
                  error={
                    touched.companyCodes &&
                    !!errors.companyCodes
                  }
                  helperText={
                    touched.companyCodes &&
                    errors.companyCodes
                      ? errors.companyCodes
                      : ""
                  }
                  label="Company Code"
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="">Select Company Code</MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem
                      key={index}
                      value={code}
                    >
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              ) : (
                <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Company Code"
                          value={companycode}
                          name="companyCodes"
                          sx={{ gridColumn: "span 2" }}
                        />
              )}
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            mt="20px"
          >
            <Button
              type="submit"
              color="secondary"
              variant="contained"
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default Addlead;
