import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import axios from "../../../utils/axios";

import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";
import { useUser } from "../../../contexts/auth";

const TaxUpdate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { clientId } = useParams();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ownername: "",
    address: "",
    guardianname: "",
    vehicleno: "",
    phoneno: "",
    chassisno: "",
    startdate: null,
    expiredate: null,
    taxtype: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [datas, setData] = useState({});
  const [adhardocument, setAdhardocument] = useState(null);
  const [pan, setPan] = useState(null);
  const [Rc, setRc] = useState(null);
  const [other, setOther] = useState(null);
  const [tax, setTax] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChangeAdhar = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAdhardocument(selectedFiles);
      handleInputChange({
        target: {
          name: "adhardocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangePan = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPan(selectedFiles);
      handleInputChange({
        target: {
          name: "pandocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeRc = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setRc(selectedFiles);
      handleInputChange({
        target: {
          name: "rcdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeOther = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setOther(selectedFiles);
      handleInputChange({
        target: {
          name: "otherdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeTax = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setTax(selectedFiles);
      handleInputChange({
        target: {
          name: "tax",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handlestartDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startdate: date || null,
    }));
  };

  const handleexpireDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      expiredate: date || null,
    }));
  };

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };
  const loadtax = async () => {
    const res = await axios.get(`/gettaxdoc/${clientId}`, {});
    setData(res.data.client);
  };
  console.log(datas);
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`/getTax/${clientId}`);
        if (response.status === 200) {
          const clientData = response.data.client;
          setFormData({
            ...clientData,
            registrationdate: clientData.registrationdate || null,
            startdate: clientData.startdate || null,
            expiredate: clientData.expiredate || null,
            taxtype: clientData.taxtype || "",
          });
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientId) {
      fetchClientData();
      loadtax();
    }
  }, [clientId]);

  const handleEditClick = () => {
    setIsEditMode(true); // Enable edit mode
    setIsSubmitting(false); // Reset submission status on edit click
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("formData", JSON.stringify(formData));
      console.log(JSON.stringify(formData));
      formDataToSend.append("pandocument", pan);
      formDataToSend.append("adhardocument", adhardocument);
      formDataToSend.append("rcdocument", Rc);
      formDataToSend.append("otherdocument", other);
      formDataToSend.append("taxdocument", tax);
      formDataToSend.append("username", username);
      const response = await axios.post(
        `/updateTax/${clientId}`,
        formDataToSend
      );
      if (response.status === 200) {
        setSuccessMessage("Client Tax updated successfully");
        setOpenSuccess(true);
        setIsEditMode(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error updating client tax data:", error.response.data);
      setIsSubmitting(false);
    }
  };

  const handleTaxFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <form onSubmit={handleUpdate}>
        <Box m="20px">
        <Button
            onClick={() => navigate(`/taxtimeline/${clientId}`)}
            variant="contained"
          >
            Timeline
          </Button>
          <Header
            title="Tax Details Update"
            subtitle="Update your Tax details"
          />
          <Typography variant="h6" align="left">
            Service Types: <b>Tax</b>
          </Typography>
          <br></br>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Owner Name"
              onChange={handleInputChange}
              value={formData.ownername}
              name="ownername"
              size="small"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onChange={handleInputChange}
              value={formData.address}
              name="ownername"
              size="small"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Guardian Name"
              onChange={handleInputChange}
              value={formData.guardianname}
              name="guardianname"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Vechile Number"
              onChange={handleInputChange}
              value={formData.vehicleno}
              name="vehicleno"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Phone Number"
              onChange={handleInputChange}
              value={formData.phoneno}
              name="phoneno"
              sx={{ gridColumn: "span 2" }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Start Date</span>
                value={formData.startdate ? dayjs(formData.startdate) : null}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                //
                onChange={handlestartDateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Expiry Date</span>
                value={formData.expiredate ? dayjs(formData.expiredate) : null}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                onChange={handleexpireDateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Chanssis Number"
              onChange={handleInputChange}
              value={formData.chassisno}
              name="chassisno"
              // error={!!touched.chassisno && !!errors.chassisno}
              // helperText={touched.chassisno && errors.chassisno}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField //--------------------------------------Pan File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Pan"
              // onBlur={handleBlur}
              onChange={handleFileChangePan}
              value={datas.pancard}
              name="panedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                // endAdornment: (
                //   // <InputAdornment position="start">
                //   //   <UploadFileIcon
                //   //     style={{ cursor: "pointer" }}
                //   //     //onClick={() => handleIconClick()} // Corrected syntax
                //   //   />
                //   //   <input
                //   //     type="file"
                //   //     accept=".pdf"
                //   //     name="pandocument"
                //   //     //ref={fileInputRef}
                //   //     style={{
                //   //       position: "absolute",
                //   //       top: 0,
                //   //       left: 0,
                //   //       width: "100%",
                //   //       height: "100%",
                //   //       opacity: 0,
                //   //       cursor: "pointer",
                //   //       zIndex: 2,
                //   //     }}
                //   //     onChange={handleFileChangePan}
                //   //   />
                //   // </InputAdornment>
                // ),
              }}
            />
            <TextField //------------------------------------------Aadhar File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Aadhar"
              // onBlur={handleBlur}
              onChange={handleFileChangeAdhar}
              value={datas.adharcard}
              name="adhardocumentx"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="pandocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeAdhar}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------RC File Upload
              fullWidth
              variant="filled"
              type="text"
              label="RC"
              // onBlur={handleBlur}
              onChange={handleFileChangeRc}
              value={datas.rc}
              name="rcdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="rcdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeRc}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Tax File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Pollution"
              // onBlur={handleBlur}
              onChange={handleFileChangeTax}
              value={datas.tax}
              name="taxdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="fitnessdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeFitness}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Other File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Other Document"
              // onBlur={handleBlur}
              onChange={handleFileChangeOther}
              value={datas.otherdocument}
              name="otherdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="otherdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeOther}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Box>
        </Box>
      </form>
      {/* Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default TaxUpdate;
