

import React, { useState, useEffect } from "react";
import * as Yup from "yup"; // Import Yup for validation
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "../../../utils/axios";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";


import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../assets/charts/Header";

import { useUser } from "../../../contexts/auth";

const PollutionUpdate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { clientId } = useParams();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ownername: "",
    address: "",
    guardianname: "",
    vehicleno: "",
    phoneno: "",
    registrationno: "",
    registrationdate: null,
    startdate: null,
    expiredate: null,
    pollutiontype: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [datas, setData] = useState({});
  const [adhardocument, setAdhardocument] = useState(null);
  const [pan, setPan] = useState(null);
  const [Rc, setRc] = useState(null);
  const [other, setOther] = useState(null);
  const [pollution, setPollution] = useState(null);

  const loadPoluttion = async () => {
    const res = await axios.get(`/getpollutiondoc/${clientId}`, {});
    setData(res.data.client);
    console.log(res.data.client);
  };
  console.log(datas);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleregistrationdateChange = (date) => {
    let formattedDate = null;

    if (date) {
      formattedDate = dayjs(date).isValid() ? dayjs(date).toISOString() : null;
    }

    setFormData({
      ...formData,
      registrationdate: formattedDate,
    });
  };
  const handleFileChangeAdhar = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAdhardocument(selectedFiles);
      handleInputChange({
        target: {
          name: "adhardocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangePan = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPan(selectedFiles);
      handleInputChange({
        target: {
          name: "pandocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeRc = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setRc(selectedFiles);
      handleInputChange({
        target: {
          name: "rcdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeOther = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setOther(selectedFiles);
      handleInputChange({
        target: {
          name: "otherdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangePollution = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPollution(selectedFiles);
      handleInputChange({
        target: {
          name: "pollutiondocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handlestartDateChange = (date) => {
    let formattedstartDate = null;

    if (date) {
      formattedstartDate = dayjs(date).isValid()
        ? dayjs(date).toISOString()
        : null;
    }

    setFormData({
      ...formData,
      startdate: formattedstartDate,
    });
  };

  const handleexpireDateChange = (date) => {
    let formattedexpireDate = null;

    if (date) {
      formattedexpireDate = dayjs(date).isValid()
        ? dayjs(date).toISOString()
        : null;
    }

    setFormData({
      ...formData,
      expiredate: formattedexpireDate,
    });
  };

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`/getpollution/${clientId}`);
        if (response.status === 200) {
          const clientData = response.data.client;

          setFormData({
            // Update formData with clientData received
            ...clientData,
            registrationdate: clientData.registrationdate
              ? dayjs(clientData.registrationdate).toDate()
              : null,
            startdate: clientData.startdate
              ? dayjs(clientData.startdate).toDate()
              : null,
            expiredate: clientData.expiredate
              ? dayjs(clientData.expiredate).toDate()
              : null,
            pollutiontype: clientData.pollutiontype || "",
          });

          setValues({
            ...clientData,
           
          });
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (clientId) {
      // Ensure itemId exists before fetching data
      fetchClientData();
      loadPoluttion();
    }
  }, [clientId]);

  const handleEditClick = () => {
    setIsEditMode(true); // Enable edit mode
    setIsSubmitting(false); // Reset submission status on edit click
  };



  const ValidationSchemaPollution = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    phoneno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
    registrationno: Yup.string().required(
      "Please enter the Registration Number"
    ),
  });





  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    validationSchema: ValidationSchemaPollution,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      try {
        const formDataToSend = new FormData();
  
        formDataToSend.append("formData", JSON.stringify(values));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("pollutiondocument", pollution);
        formDataToSend.append("username", username);
        const response = await axios.post(
          `/updatePollution/${clientId}`,
          formDataToSend
        );
        if (response.status === 200) {
          setSuccessMessage("Client updated successfully");
          setOpenSuccess(true);
          setIsEditMode(false);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error updating client data:", error.response.data);
        setIsSubmitting(false);
      }
    },
  });




  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   try {
  //     const formDataToSend = new FormData();

  //     formDataToSend.append("formData", JSON.stringify(formData));
  //     formDataToSend.append("pandocument", pan);
  //     formDataToSend.append("adhardocument", adhardocument);
  //     formDataToSend.append("rcdocument", Rc);
  //     formDataToSend.append("otherdocument", other);
  //     formDataToSend.append("pollutiondocument", pollution);
  //     formDataToSend.append("username", username);
  //     const response = await axios.post(
  //       `/updatePollution/${clientId}`,
  //       formDataToSend
  //     );
  //     if (response.status === 200) {
  //       setSuccessMessage("Client updated successfully");
  //       setOpenSuccess(true);
  //       setIsEditMode(false);
  //       setIsSubmitting(false);
  //     }
  //   } catch (error) {
  //     console.error("Error updating client data:", error.response.data);
  //     setIsSubmitting(false);
  //   }
  // };

  // Function to handle changes in pollutiontype
  const handlePollutionFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
        <Button
            onClick={() => navigate(`/pollutiontimeline/${clientId}`)}
            variant="contained"
          >
            Timeline
          </Button>
          <Header
            title="Pollution Details Update"
            subtitle="Update your Polution details"
          />
          <Typography variant="h6" align="left">
            Service Types: <b>Pollution</b>
          </Typography>
          <br></br>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Owner Name"
              onChange={handleChange}
              value={values.ownername}
              name="ownername"
              size="small"
              error={touched.ownername && !!errors.ownername}
              helperText={
                touched.ownername && errors.ownername
                  ? "owner name is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onChange={handleChange}
              value={values.address}
              name="ownername"
              size="small"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Guardian Name"
              onChange={handleChange}
              value={values.guardianname}
              name="guardianname"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Vechile Number"
              onChange={handleChange}
              value={values.vehicleno}
              name="vehicleno"
              error={touched.vehicleno && !!errors.vehicleno}
              helperText={
                touched.vehicleno && errors.vehicleno
                  ? "vehicle number is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Phone Number"
              onChange={handleChange}
              value={values.phoneno}
              name="phoneno"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Registration Number"
              onChange={handleChange}
              value={values.registrationno}
              name="registrationno"
              error={touched.registrationno && !!errors.registrationno}
              helperText={
                touched.registrationno && errors.registrationno
                  ? "registration number is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                //className={classes.customTextField}
                label=<span>Registration Date</span>
                value={
                  formData.registrationdate
                    ? dayjs(values.registrationdate)
                    : null
                }
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                // onChange={(date) =>
                //   handleDateChange(date, "registrationdate")
                // }
                onChange={handleregistrationdateChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <TextField //--------------------------------------Pan File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Pan"
              // onBlur={handleBlur}
              onChange={handleFileChangePan}
              value={datas.pancard}
              name="panedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                // endAdornment: (
                //   // <InputAdornment position="start">
                //   //   <UploadFileIcon
                //   //     style={{ cursor: "pointer" }}
                //   //     //onClick={() => handleIconClick()} // Corrected syntax
                //   //   />
                //   //   <input
                //   //     type="file"
                //   //     accept=".pdf"
                //   //     name="pandocument"
                //   //     //ref={fileInputRef}
                //   //     style={{
                //   //       position: "absolute",
                //   //       top: 0,
                //   //       left: 0,
                //   //       width: "100%",
                //   //       height: "100%",
                //   //       opacity: 0,
                //   //       cursor: "pointer",
                //   //       zIndex: 2,
                //   //     }}
                //   //     onChange={handleFileChangePan}
                //   //   />
                //   // </InputAdornment>
                // ),
              }}
            />
            <TextField //------------------------------------------Aadhar File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Aadhar"
              // onBlur={handleBlur}
              onChange={handleFileChangeAdhar}
              value={datas.adharcard}
              name="adhardocumentx"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="pandocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeAdhar}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------RC File Upload
              fullWidth
              variant="filled"
              type="text"
              label="RC"
              // onBlur={handleBlur}
              onChange={handleFileChangeRc}
              value={datas.rc}
              name="rcdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="rcdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeRc}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Pollution File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Pollution"
              // onBlur={handleBlur}
              onChange={handleFileChangePollution}
              value={datas.pollution}
              name="pollutiondocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="fitnessdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeFitness}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />

            <TextField //------------------------------------------Other File Upload
              fullWidth
              variant="filled"
              type="text"
              label="Other Document"
              // onBlur={handleBlur}
              onChange={handleFileChangeOther}
              value={datas.otherdocument}
              name="otherdocument"
              sx={{ gridColumn: "span 2" }}
              // InputProps={{
              //   readOnly: true, // Disable text input
              //   endAdornment: (
              //     <InputAdornment position="start">
              //       <UploadFileIcon
              //         style={{ cursor: "pointer" }}
              //         //onClick={() => handleIconClick()} // Corrected syntax
              //       />
              //       <input
              //         type="file"
              //         accept=".pdf"
              //         name="otherdocument"
              //         //ref={fileInputRef}
              //         style={{
              //           position: "absolute",
              //           top: 0,
              //           left: 0,
              //           width: "100%",
              //           height: "100%",
              //           opacity: 0,
              //           cursor: "pointer",
              //           zIndex: 2,
              //         }}
              //         // onChange={handleFileChangePan}
              //         onChange={handleFileChangeOther}
              //       />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Box>
        </Box>
      </form>
      {/* Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default PollutionUpdate;
