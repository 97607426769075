


import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";


const LeadsTimeLine = () => {
  const { lead } = useParams();
  console.log(lead)
  const [leadData, setLeadData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get(`/getUpdatedLeadById/${lead}`);
        const data = response.data;
        console.log("Lead Data:", data);
        setLeadData(data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanyData();
  }, [lead]);

  return (
    <>
      <Helmet>
        <title>ContactTab | User Timeline</title>
      </Helmet>

      <div>
        {leadData ? (
          <div>
            <h2>User Timeline</h2>
            <ul>
              {Object.entries(leadData).map(([fieldName, value]) => (
                <li key={fieldName}>
                  {typeof value === 'object' && value !== null ? (
                    <>
                      {/* {fieldName}: */}
                      <ul>
                        <li>
                        {fieldName}  was updated from {value.oldValue !== undefined ? value.oldValue : 'blank'} to {value.newValue !== undefined ? value.newValue : 'blank'}
                        </li>
                      </ul>
                    </>
                  ) : (
                    `${fieldName} was updated from blank value to : ${value}`

                    
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  );
};

export default LeadsTimeLine;





