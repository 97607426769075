

import React, { useState, useEffect } from "react";

import {
  Box,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";


import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../../assets/charts/Header";
import { useUser } from "../../../contexts/auth";

const Alllead = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const fetchData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
      const response = await axios.get("/getLeads", {
        // headers: {
        //   Authorization: `Bearer ${token}`,//Include the token for Authorization
        // },
        params: {
          companycode:companycode, // Include the companycode in the query parameters
          userrole: userrole, // Include the userrole in the query parameters
        },
      });
      if (response && response.data) {
        setLeads(response.data);
      }
    }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [companycode,userrole]);

  const handleStatusChange = async (event, lead) => {
    try {
      const updatedLeads = leads.map((lead) =>
        lead.id === lead ? { ...lead, leadstatus: event.target.value } : lead
      );
      setLeads(updatedLeads);

      const response = await axios.put(
        `/updateLeadState/${lead}`,
        {
          leadstatus: event.target.value,
        }
      );

      if (response && response.status === 200) {
        fetchData();
        setDeleteSuccessMessage("Lead status updated successfully!");
      } else {
        console.error("Failed to update lead status");
      }
    } catch (error) {
      console.error("Error updating lead status:", error);
    }
  };

  const handleEdit = (lead) => {
    navigate(`/updatelead/${lead}`);
    
  };

  const handleDelete = (leadId) => {
    setDeleteLeadId(leadId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `/deleteLead/${deleteLeadId}`
      );

      if (response && response.status === 200) {
        setLeads((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== deleteLeadId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }

    setOpenDialog(false);
  };

 

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    { field: "leadname", headerName: "Lead Name", flex: 0.3 },
    { field: "leademail", headerName: "Email", flex: 0.3, cellClassName: "name-column--cell" },
    { field: "contactno", headerName: "Contact Number", flex: 0.3 },
    { field: "leadsource", headerName: "Source", flex: 0.3 },
    { field: "leadcomments", headerName: "Comments", flex: 0.3 },
    {
      field: "leadstate",
      headerName: "Status",
      flex: 0.3,
      renderCell: (params) => <span>{params.row.leadstate}</span>,
    },
    { field: "companycode", headerName: "Company Code", flex: 0.3 },
    {
      field: "leadStatus",
      headerName: "Lead State",
      flex: 0.3,
      renderCell: (params) => (
        <Select
          className="select-menu"
          value={params.row.leadType}
          onChange={(event) => handleStatusChange(event, params.row.id)}
        >
          <MenuItem value="Pitched">Pitched</MenuItem>
          <MenuItem value="Negotiation">Negotiation</MenuItem>
          <MenuItem value="Demo">Demo</MenuItem>
          <MenuItem value="Closed Lost">Closed Lost</MenuItem>
          <MenuItem value="Closed Won">Closed Won</MenuItem>
          <MenuItem value="RNR">RNR</MenuItem>
          <MenuItem value="Junk">Junk</MenuItem>
        </Select>
      ),
    },
    {
      headerName: "Actions",
      flex: 0.3,
      renderCell: (props) => (
        <>
          <EditIcon
            style={{ marginRight: "10px" }}
            onClick={() => handleEdit(props.row.id)}
          />
          <DeleteIcon onClick={() => handleDelete(props.row.id)} />
        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Lead List" subtitle="List of Leads..." />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid rows={leads} columns={columns} components={{ Toolbar: GridToolbar }} />
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this lead?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Alllead;
