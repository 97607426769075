import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataContacts } from "../../../data/mockData";

import { useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "../../../utils/axios";

import { Edit, Delete } from "@mui/icons-material";

import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Header from "../../../assets/charts/Header";
import { useUser } from "../../../contexts/auth";

const Allclient = () => {
  const theme = useTheme();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [businessType, setBusinessType] = useState("insurance");
  const [tableData, setTableData] = useState([]);
  const [deletionItem, setDeletionItem] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (companycode !== undefined && userrole !== undefined) {
        let endpoint = "";
        if (businessType === "insurance") {
          endpoint = "/getInsuranceRegistrations";
        } else if (businessType === "fitness") {
          endpoint = "/getFitnessRegistrations";
        } else if (businessType === "permit") {
          endpoint = "/getPermitRegistrations";
        } else if (businessType === "pollution") {
          endpoint = "/getPollutionRegistrations";
        } else if (businessType === "tax") {
          endpoint = "/getTaxRegistrations";
        }

        const response = await axios.get(endpoint, {params: {
          companycode:companycode, // Include the companycode in the query parameters
          userrole: userrole, // Include the userrole in the query parameters
        },});
        // Ensure the response.data is an array
        // console.log(response.data.allData);
        if (Array.isArray(response.data.allData)) {
          setTableData(response.data.allData);
        } else {
          console.error("Invalid data format:", response.data);
        }
      }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [businessType]);

  const handleDelete = (clientId) => {
    console.log(clientId)
    setDeletionItem(clientId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
  
    try {
      
      console.log(deletionItem)
      if (isNaN(deletionItem)) {
        console.log("Invalid itemId:", deletionItem);
        return; // Do not proceed with deletion if itemId is not a number
      }
      console.log(deletionItem);

      let endpoint = "";

      if (businessType === "insurance") {
        endpoint = `/deleteInsurance/${deletionItem}`;
      } else if (businessType === "fitness") {
        endpoint = `/deleteFitness/${deletionItem}`;
      } else if (businessType === "permit") {
        endpoint = `/deletePermit/${deletionItem}`;
      } else if (businessType === "pollution") {
        endpoint = `/deletePollution/${deletionItem}`;
      } else if (businessType === "tax") {
        endpoint = `/deleteTax/${deletionItem}`;
      }

      await axios.delete(endpoint);

      const updatedTableData = tableData.filter(
        (data) => data.id !== deletionItem
      );
      setTableData(updatedTableData);
      setDeletionItem(null);
      setDeleteSuccess(true);
      setOpenSnackbar(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, 3000);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleBusinessTypeChange = (event) => {
    setBusinessType(event.target.value);
  };

  const columns = () => {
    switch (businessType) {
      case "insurance":
        return [
          { field: "id", headerName: "ID", flex: 0.1 },
          { field: "ownername", headerName: "Owner Name", flex: 0.3 },
          { field: "address", headerName: "Address", flex: 0.3 },
          { field: "guardianname", headerName: "Guardian Name", flex: 0.3 },
          { field: "vehicleno", headerName: "Vehicle No", flex: 0.3 },
          { field: "companycode", headerName: "Companycode", flex: 0.3 },
          // Add other columns specific to insurance
          {
            headerName: "Actions",
            flex: 2,
            renderCell: (props) => buttonAlign(props),
          },
        ];
      case "tax":
        return [
          { field: "id", headerName: "ID", flex: 0.1 },
          { field: "ownername", headerName: "Owner Name", flex: 0.3 },
          { field: "address", headerName: "Address", flex: 0.3 },
          { field: "guardianname", headerName: "Guardian Name", flex: 0.3 },
          { field: "vehicleno", headerName: "Vehicle No", flex: 0.3 },
          { field: "companycode", headerName: "Companycode", flex: 0.3 },
          // Add other columns specific to tax
          {
            headerName: "Actions",
            flex: 2,
            renderCell: (props) => buttonAlign(props),
          },
        ];
      case "permit":
        return [
          { field: "id", headerName: "ID", flex: 0.1 },
          { field: "ownername", headerName: "Owner Name", flex: 0.3 },
          { field: "address", headerName: "Address", flex: 0.3 },
          { field: "guardianname", headerName: "Guardian Name", flex: 0.3 },
          { field: "vehicleno", headerName: "Vehicle No", flex: 0.3 },
          { field: "companycode", headerName: "Companycode", flex: 0.3 },
          // Add other columns specific to permit
          {
            headerName: "Actions",
            flex: 2,
            renderCell: (props) => buttonAlign(props),
          },
        ];
      case "pollution":
        return [
          { field: "id", headerName: "ID", flex: 0.1 },
          { field: "ownername", headerName: "Owner Name", flex: 0.3 },
          { field: "address", headerName: "Address", flex: 0.3 },
          { field: "gaurdianname", headerName: "Guardian Name", flex: 0.3 },
          { field: "vehicleno", headerName: "Vehicle No", flex: 0.3 },
          { field: "companycode", headerName: "Companycode", flex: 0.3 },
          // Add other columns specific to pollution
          {
            headerName: "Actions",
            flex: 2,
            renderCell: (props) => buttonAlign(props),
          },
        ];
      case "fitness":
        return [
          { field: "id", headerName: "ID", flex: 0.1 },
          { field: "ownername", headerName: "Owner Name", flex: 0.3 },
          { field: "vehicleno", headerName: "Vehicle No", flex: 0.3 },
          { field: "engineno", headerName: "Engine No", flex: 0.3 },
          { field: "chassisno", headerName: "Chassis No", flex: 0.3 },
          { field: "companycode", headerName: "Companycode", flex: 0.3 },
          // Add other columns specific to fitness
          {
            headerName: "Actions",
            flex: 2,
            renderCell: (props) => buttonAlign(props),
          },
        ];
      default:
        return [];
    }
  };

  const options = {
    filter: true,
    selectableRows: "none",
  };

  const buttonAlign = (props) => {
    const handleEdit = (clientId) => {
      switch (businessType) {
        case "insurance":
          navigate(`/edit-insurance/${clientId}`);
          break;
        case "fitness":
          navigate(`/edit-fitness/${clientId}`);
          break;
        case "permit":
          navigate(`/edit-permit/${clientId}`);
          break;
        case "pollution":
          navigate(`/edit-pollution/${clientId}`);
          break;
        case "tax":
          navigate(`/edit-tax/${clientId}`);
          break;
        default:
          break;
      }
    };

    return (
      <>
        <EditIcon
          style={{ marginRight: "5px" }}
          onClick={() => handleEdit(props.row.id)}
        />
        <DeleteIcon
          onClick={() => {
            console.log(props.row.id);
            handleDelete(props.row.id);
          }}
        />
      </>
    );
  };

  return (
    <Box m="20px">
      <Header
        title="Lead List"
        subtitle="List of Leads..."
      />
      <FormControl>
        <Select
          value={businessType}
          onChange={handleBusinessTypeChange}
          fullWidth
          size="small"
          className="ms-3"
        >
          <MenuItem value="insurance">Insurance</MenuItem>
          <MenuItem value="fitness">Fitness</MenuItem>
          <MenuItem value="permit">Permit</MenuItem>
          <MenuItem value="pollution">Pollution</MenuItem>
          <MenuItem value="tax">Tax</MenuItem>
        </Select>
      </FormControl>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={tableData}
          columns={columns()} // Call the columns function to get the array of columns
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this lead?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Allclient;
