import React, { useState, useEffect } from "react";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup"; // Import Yup for validation
import { useFormik } from "formik";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs, { Dayjs } from "dayjs";
import { InputAdornment } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";

const Addclient = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [companyCodes, setCompanyCodes] = useState([]);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
console.log(userrole,
  username,
  userid,
  companycode,
  isAuthRole,
  isAuthorized,
  token,)
  const initialValuesInsurance = {
    ownername: "",
    address: "",
    guardianname: "",
    vehicleno: "",
    phoneno: "",
    registrationno: "",
    registrationdate: null,
    ncb: "",
    cd: "",
    engineno: "",
    chassisno: "",
    odpremium: "",
    netpremium: "",
    finalpremium: "",
    policyno: "",
    idvvalue: "",
    macmodule: "",
    startdate: null,
    expiredate: null,
    penality: "",
    taxtype: "",
    permittype: "",
    companyCodes:userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };

  const initialValuesFitness = {
    ownername: "",
    guardianname: "",
    vehicleno: "",
    engineno: "",
    chassisno: "",
    phoneno:"",
    startdate: null,
    expiredate: null,
    companyCodes:userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };

  const initialValuesPermit = {
    ownername: "",
    address: "",
    guardianname: "",
    vehicleno: "",
    phoneno: "",
    permittype: "",
    startdate: null,
    expiredate: null,
    companyCodes:userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };

  const initialValuesPollution = {
    ownername: "",
    address: "",
    guardianname: "",
    vehicleno: "",
    phoneno: "",
    registrationno: "",
    registrationdate: null,
    startdate: null,
    expiredate: null,
    companyCodes:userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };

  const initialValuesTax = {
    ownername: "",
    address: "",
    guardianname: "",
    vehicleno: "",
    phoneno: "",
    taxtype: "",
    startdate: null,
    expiredate: null,
    chassisno: "",
    companyCodes:userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };

  const ValidationSchemaInsurance = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    registrationno: Yup.string().required("Please enter the Registration Number"),
    phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
    .min(10, "Mobile Number must be at least 10 characters")
    .max(10, "Mobile Number must be at most 10 characters")
    .required("Mobile Number is required"),
    ncb: Yup.string().required("Please enter the NCB "),
    cd: Yup.string().required("Please enter the CD"),
    engineno: Yup.string().required("Please enter the Engine Number"),
    chassisno: Yup.string().required("Please enter the Chassis Number"),
    odpremium: Yup.string().required("Please enter the OD premium value"),
    netpremium: Yup.string().required("Please enter the Net premium value"),
    finalpremium: Yup.string().required("Please enter the Final Premium value"),
    policyno: Yup.string().required("Please enter the Policy Number"),
    idvvalue: Yup.string().required("Please enter the IDV value"),
    macmodule: Yup.string().required("Please enter the Vehicle Number"),
   // expiredate: Yup.date().required("Expiration date is required").nullable(),
  });

  const ValidationSchemaFitness = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    engineno: Yup.string().required("Please enter the Engine Number"),
    phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
    .min(10, "Mobile Number must be at least 10 characters")
    .max(10, "Mobile Number must be at most 10 characters")
    .required("Mobile Number is required"),
    chassisno: Yup.string().required("Please enter the Chassis Number"),
  });

  const ValidationSchemaPermit = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    phoneno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
  });

  const ValidationSchemaPollution = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    phoneno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
    registrationno: Yup.string().required(
      "Please enter the Registration Number"
    ),
  });

  const ValidationSchemaTax = Yup.object({
    ownername: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Owner name must contain only letters")
      .required("Please enter the ownername"),
    vehicleno: Yup.string().required("Please enter the Vehicle Number"),
    phoneno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
    // taxtype: Yup.string()

    // .required("Please select the Tax type"),
  });

  //documents
  const [adhardocument, setAdhardocument] = useState(null);
  const [pan, setPan] = useState(null);
  const [Rc, setRc] = useState(null);
  const [other, setOther] = useState(null);
  const [insurance, setInsurance] = useState(null);

  //
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  //forms
  const [permit, setPermit] = useState(null);
  const [fitness, setFitness] = useState(null);
  const [pollution, setPollution] = useState(null);
  const [tax, setTax] = useState(null);
  const [businessType, setBusinessType] = useState("insurance");

  //to open particular form
  const [showInsuranceForm, setShowInsuranceForm] = useState(true);
  const [showFitnessForm, setShowFitnessForm] = useState(false);
  const [showPermitForm, setShowPermitForm] = useState(false);
  const [showPollutionForm, setShowPollutionForm] = useState(false);
  const [showTaxForm, setShowTaxForm] = useState(false);

  //pollution and tax type
  const [pollutionType, setPollutionType] = useState("");
  const [setTaxType] = useState("");


  //for file names to be displayed
  const [selectedFileNameInsurance, setSelectedFileNameInsurance] = useState("");
  const [selectedFileNameFitness, setSelectedFileNameFitness] = useState("");
  const [selectedFileNamePermit, setSelectedFileNamePermit] = useState("");
  const [selectedFileNamePollution, setSelectedFileNamePollution] = useState("");
  const [selectedFileNameTax, setSelectedFileNameTax] = useState("");
  const [selectedFileNameOther, setSelectedFileNameOther] = useState("");
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const [selectedFileNameRc, setSelectedFileNameRc] = useState("");
  const [selectedFileNameAdhar, setSelectedFileNameAdhar] = useState("");

  const handleFileChangeAdhar = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNameAdhar(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAdhardocument(selectedFiles);
      handleChange({
        target: {
          name: "adhardocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangePollution = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePollution(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPollution(selectedFiles);
      handleChange({
        target: {
          name: "pollutiondocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const handleFileChangeTax = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNameTax(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setTax(selectedFiles);
      handleChange({
        target: {
          name: "tax",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangePan = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPan(selectedFiles);
      handleChange({
        target: {
          name: "pandocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const handleFileChangeRc = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNameRc(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setRc(selectedFiles);
      handleChange({
        target: {
          name: "rcdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeOther = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNameOther(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setOther(selectedFiles);
      handleChange({
        target: {
          name: "otherdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangepermit = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePermit(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setPermit(selectedFiles);
      handleChange({
        target: {
          name: "permitdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeinsurance = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNameInsurance(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setInsurance(selectedFiles);
      handleChange({
        target: {
          name: "insurancedocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeFitness = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNameFitness(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setFitness(selectedFiles);
      handleChange({
        target: {
          name: "fitnessdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleBusinessTypeChange = (event) => {
    const selectedType = event.target.value;
    setBusinessType(selectedType);

    // Show respective forms based on selected business type
    setShowInsuranceForm(selectedType === "insurance");
    setShowFitnessForm(selectedType === "fitness");
    setShowPermitForm(selectedType === "permit");
    setShowPollutionForm(selectedType === "pollution");
    setShowTaxForm(selectedType === "tax");
  };

  const handleDateChange = (date) => {
    if (date) {
      const localDate = dayjs(date).format('YYYY-MM-DD'); // Convert to local date string
      setFieldValue("expiredate", localDate);
    } else {
      setFieldValue("expiredate", null);
    }
  };
  
  const handleFitnessDateChange = (date) => {
    if (date) {
      const localDate = dayjs(date).format('YYYY-MM-DD');
      setFieldsValue("expiredate", localDate);
    } else {
      setFieldsValue("expiredate", null);
    }
  };
  
  const handlePermitDateChange = (date) => {
    if (date) {
      const localDate = dayjs(date).format('YYYY-MM-DD');
      setFieldValues("expiredate", localDate);
    } else {
      setFieldValues("expiredate", null);
    }
  };
  const handlePollutionDateChange = (date) => {
    if (date) {
      const localDate = dayjs(date).format('YYYY-MM-DD');
      setFieldValuepollution("expiredate", localDate);
    } else {
      setFieldValuepollution("expiredate", null);
    }
  };
  const handleTaxDateChange = (date) => {
    if (date) {
      const localDate = dayjs(date).format('YYYY-MM-DD');
      setFieldValueTax("expiredate", localDate);
    } else {
      setFieldValueTax("expiredate", null);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        console.log(response.data)
        const formattedCodes = response.data.map((company) =>
          company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  //insurance
  const {
    values,
    errors: insuranceErrors,
    touched: insuranceTouched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValuesInsurance,
    validationSchema: ValidationSchemaInsurance,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
         // Check if expiredate is null
    if (values.expiredate === null) {
      alert("Expire date is required");
      return; // Stop form submission
    }
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(values));
        // console.log(JSON.stringify(formData));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("insurancedocument", insurance);
        formDataToSend.append("username", username);

       console.log( JSON.stringify(values));
        // console.log(pan);

        const response = await axios.post("/addClient", formDataToSend, {});

        if (response.status === 201) {
          setSuccessMessage("Form submitted successfully!");
          setOpenSuccess(true);

          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
          resetForm();
          setSelectedFileNameAdhar("");
          setSelectedFileNameRc("");
          setSelectedFileNamePan("");
          setSelectedFileNameOther(""); 
          setSelectedFileNameInsurance("");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  //fitness
  const {
    values: fitnessValues,
    errors: fitnessErrors,
    touched: fitnessTouched,
    handleBlur: fitnessHandleBlur,
    handleChange: fitnessHandleChange,
    handleReset: fitnessHandleReset,
    handleSubmit: fitnessHandleSubmit,
    setFieldValue:setFieldsValue,
  } = useFormik({
    initialValues: initialValuesFitness,
    validationSchema: ValidationSchemaFitness,
    onSubmit: async (values, {resetForm}) => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
          // Check if expiredate is null
    if (fitnessValues.expiredate === null) {
      alert("Expire date is required");
      return; // Stop form submission
    }
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(values));
        // console.log(JSON.stringify(values));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("fitnessdocument", fitness);
        formDataToSend.append("username", username);

        console.log(JSON.stringify(values));
        // console.log(adhardocument);
        // console.log(pan);
        const response = await axios.post(
          "/addClientFitnessRegistration",
          formDataToSend
        );

        if (response.status === 201) {
          setSuccessMessage("Form submitted successfully!");
          setOpenSuccess(true);

          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
          resetForm();
          setSelectedFileNameAdhar("");
          setSelectedFileNameRc("");
          setSelectedFileNamePan("");
          setSelectedFileNameOther(""); 
          setSelectedFileNameFitness("");
          // setFormDataPermit({ permitType: "" }); // Clear permitType
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  //permit
  const {
    values: permitValues,
    errors: permitErrors,
    touched: permitTouched,
    handleBlur: permitHandleBlur,
    handleChange: permitHandleChange,
    handleReset: permitHandleReset,
    handleSubmit: permitHandleSubmit,
    setFieldValue:setFieldValues,
  } = useFormik({
    initialValues: initialValuesPermit,
    validationSchema: ValidationSchemaPermit,
    onSubmit: async (values,{resetForm}) => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
         // Check if expiredate is null
    if (permitValues.expiredate === null) {
      alert("Expire date is required");
      return; // Stop form submission
    }
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(values));
        // console.log(JSON.stringify(values));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("permitdocument", permit);
        formDataToSend.append("username", username);
        console.log(adhardocument);
        console.log(pan);
        const response = await axios.post(
          "/addClientPermitRegistration",
          formDataToSend
        );

        if (response.status === 201) {
          setSuccessMessage("Form submitted successfully!");
          setOpenSuccess(true);

          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
          resetForm();
          setSelectedFileNameAdhar("");
          setSelectedFileNameRc("");
          setSelectedFileNamePan("");
          setSelectedFileNameOther(""); 
          setSelectedFileNamePermit("");
          // setFormDataPermit({ permitType: "" }); // Clear permitType
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  //pollution

  const {
    values: pollutionValues,
    errors: pollutionErrors,
    touched: pollutionTouched,
    handleBlur: pollutionHandleBlur,
    handleChange: pollutionHandleChange,
    handleReset: pollutionHandleReset,
    handleSubmit: pollutionHandleSubmit,
    setFieldValue:setFieldValuepollution,
  } = useFormik({
    initialValues: initialValuesPollution,
     validationSchema: ValidationSchemaPollution,
    onSubmit: async (values, {resetForm}) => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
         // Check if expiredate is null
    if (pollutionValues.expiredate === null) {
      alert("Expire date is required");
      return; // Stop form submission
    }
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(values));
        // console.log(JSON.stringify(formDataTopollution));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("pollutiondocument", pollution);
        formDataToSend.append("username", username);
        console.log(adhardocument);
        console.log(pan);

        const response = await axios.post(
          "/addClientPollutionRegistration",
          formDataToSend
        );

        if (response.status === 201) {
          setSuccessMessage("Form submitted successfully!");
          setOpenSuccess(true);

          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);

          setPollutionType("");
          resetForm();
          setSelectedFileNameAdhar("");
          setSelectedFileNameRc("");
          setSelectedFileNamePan("");
          setSelectedFileNameOther(""); 
          setSelectedFileNamePollution("");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  //tax

  const {
    values: taxValues,
    errors: taxErrors,
    touched: taxTouched,
    handleBlur: taxHandleBlur,
    handleChange: taxHandleChange,
    handleReset: taxHandleReset,
    handleSubmit: taxHandleSubmit,
    setFieldValue:setFieldValueTax,
  } = useFormik({
    initialValues: initialValuesTax,
     validationSchema: ValidationSchemaTax,
    onSubmit: async (values, {resetForm}) => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
               // Check if expiredate is null
    if (taxValues.expiredate === null) {
      alert("Expire date is required");
      return; // Stop form submission
    }
        const formDataToSend = new FormData();
        formDataToSend.append("formData", JSON.stringify(values));
        // console.log(JSON.stringify(formDataToTax));
        formDataToSend.append("pandocument", pan);
        formDataToSend.append("adhardocument", adhardocument);
        formDataToSend.append("rcdocument", Rc);
        formDataToSend.append("otherdocument", other);
        formDataToSend.append("taxdocument", tax);
        formDataToSend.append("username", username);
        console.log(JSON.stringify(values));
        const response = await axios.post(
          "/addClientTaxRegistration",
          formDataToSend
        );

        if (response.status === 201) {
          setSuccessMessage("Form submitted successfully!");
          setOpenSuccess(true);

          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);

          resetForm();
          setSelectedFileNameAdhar("");
          setSelectedFileNameRc("");
          setSelectedFileNamePan("");
          setSelectedFileNameOther(""); 
          setSelectedFileNameTax("");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  return (
    <>
      <Box m="20px">
        <Header
          title="Client Details"
          subtitle="Enter your Client details"
        />
        <Typography
          variant="h6"
          align="left"
        >
          Service Types
        </Typography>
        <FormControl>
          <Select
            value={businessType}
            onChange={handleBusinessTypeChange}
            fullWidth
            size="small"
          >
            <MenuItem value="insurance">Insurance</MenuItem>
            <MenuItem value="fitness">Fitness</MenuItem>
            <MenuItem value="permit">Permit</MenuItem>
            <MenuItem value="pollution">Pollution</MenuItem>
            <MenuItem value="tax">Tax</MenuItem>
          </Select>
        </FormControl>
        <hr></hr>

        {showInsuranceForm && (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Owner Name"
                onChange={handleChange}
                value={values.ownername}
                name="ownername"
                size="small"
                error={insuranceTouched.ownername && !!insuranceErrors.ownername}
                helperText={
                  insuranceTouched.ownername && insuranceErrors.ownername
                    ? "Owner name is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onChange={handleChange}
                value={values.address}
                name="address"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Guardian Name"
                onChange={handleChange}
                value={values.guardianname}
                name="guardianname"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Vechile Number"
                onChange={handleChange}
                value={values.vehicleno}
                name="vehicleno"
                error={insuranceTouched.vehicleno && !!insuranceErrors.vehicleno}
                helperText={
                  insuranceTouched.vehicleno && insuranceErrors.vehicleno
                    ? "Vehicle number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onChange={handleChange}
                value={values.phoneno}
                name="phoneno"
                error={insuranceTouched.phoneno && !!insuranceErrors.phoneno}
                helperText={
                  insuranceTouched.phoneno && insuranceErrors.phoneno
                    ? "Phoneno number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Registration Number"
                onChange={handleChange}
                value={values.registrationno}
                name="registrationno"
                error={insuranceTouched.registrationno && !!insuranceErrors.registrationno}
                helperText={
                  insuranceTouched.registrationno && insuranceErrors.registrationno
                    ? "Registration number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="NCB"
                onChange={handleChange}
                value={values.ncb}
                name="ncb"
                error={insuranceTouched.ncb && !!insuranceErrors.ncb}
                helperText={insuranceTouched.ncb && insuranceErrors.ncb ? "NCB is required" : ""}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="CD"
                onChange={handleChange}
                value={values.cd}
                name="cd"
                error={insuranceTouched.cd && !!insuranceErrors.cd}
                helperText={insuranceTouched.cd && insuranceErrors.cd ? "CD is required" : ""}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Engine no"
                onChange={handleChange}
                value={values.engineno}
                name="engineno"
                error={insuranceTouched.engineno && !!insuranceErrors.engineno}
                helperText={
                  insuranceTouched.engineno && insuranceErrors.engineno
                    ? "Engine Number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Chanssis Number"
                onChange={handleChange}
                value={values.chassisno}
                name="chassisno"
                error={insuranceTouched.chassisno && !!insuranceErrors.chassisno}
                helperText={
                  insuranceTouched.chassisno && insuranceErrors.chassisno
                    ? "Chassis Number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              {/* insurance od premium */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="OD Premium"
                onChange={handleChange}
                value={values.odpremium}
                name="odpremium"
                error={insuranceTouched.odpremium && !!insuranceErrors.odpremium}
                helperText={
                  insuranceTouched.odpremium && insuranceErrors.odpremium
                    ? "OPD premium is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              {/* insurance net premium */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Net Premium"
                onChange={handleChange}
                value={values.netpremium}
                name="netpremium"
                error={insuranceTouched.netpremium && !!insuranceErrors.netpremium}
                helperText={
                  insuranceTouched.netpremium && insuranceErrors.netpremium
                    ? "Net premium is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              {/* insurance final premium */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Final Premium"
                onChange={handleChange}
                value={values.finalpremium}
                name="finalpremium"
                error={insuranceTouched.finalpremium && !!insuranceErrors.finalpremium}
                helperText={
                  insuranceTouched.finalpremium && insuranceErrors.finalpremium
                    ? "Final premium is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              {/* insurance policy no */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Policy Number"
                onChange={handleChange}
                value={values.policyno}
                name="policyno"
                error={insuranceTouched.policyno && !!insuranceErrors.policyno}
                helperText={
                  insuranceTouched.policyno && insuranceErrors.policyno
                    ? "Policy number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              {/* insurance idv value */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="IDV Value"
                onChange={handleChange}
                value={values.idvvalue}
                name="idvvalue"
                error={insuranceTouched.idvvalue && !!insuranceErrors.idvvalue}
                helperText={
                  insuranceTouched.idvvalue && insuranceErrors.idvvalue
                    ? "IDV valueis required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              {/* insurance mac module */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Make Module"
                onChange={handleChange}
                value={values.macmodule}
                name="macmodule"
                error={insuranceTouched.macmodule && !!insuranceErrors.macmodule}
                helperText={
                  insuranceTouched.macmodule && insuranceErrors.macmodule
                    ? "Make module is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              {/* insurance penality */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Penality"
                onChange={handleChange}
                value={values.penality}
                name="penality"
                error={insuranceTouched.penality && !!insuranceErrors.penality}
                helperText={
                  insuranceTouched.penality && insuranceErrors.penality
                    ? "Penality is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              {/* {userrole === "Superadmin" && companycode === 1 && (
      <FormControl
        fullWidth
        variant="filled"
        sx={{ gridColumn: "span 2" }}
      >
        <InputLabel id="companyCode-label">Company Code</InputLabel>
        <Select
          labelId="companyCode-label"
          id="companyCodes"
          onChange={handleChange}
          value={values.companyCodes}
          name="companyCodes"
          error={insuranceTouched.companyCodes && !!insuranceErrors.companyCodes}
          helperText={
            insuranceTouched.companyCodes && insuranceErrors.companyCodes
              ? ""
              : ""
          }
          label="Company Code"
          MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
        >
          <MenuItem value="">Select Company Code</MenuItem>
          {companyCodes.map((code, index) => (
            <MenuItem key={index} value={code}>
              {code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )} */}
    {userrole === "Superadmin" && companycode === 1 ? (
  <FormControl
    fullWidth
    variant="filled"
    sx={{ gridColumn: "span 2" }}
  >
    <InputLabel id="companyCode-label">Company Code</InputLabel>
    <Select
      labelId="companyCode-label"
      id="companyCodes"
      onChange={handleChange}
      value={values.companyCodes}
      name="companyCodes"
      error={insuranceTouched.companyCodes && !!insuranceErrors.companyCodes}
      helperText={
        insuranceTouched.companyCodes && insuranceErrors.companyCodes
          ? ""
          : ""
      }
      label="Company Code"
      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
    >
      <MenuItem value="">Select Company Code</MenuItem>
      {companyCodes.map((code, index) => (
        <MenuItem key={index} value={code}>
          {code}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
) : (
  <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Company Code"
            value={companycode}
            name="companyCodes"
            sx={{ gridColumn: "span 2" }}
          />
)}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Registration Date</span>
                  value={values.registrationdate}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={(registrationdate) => handleChange({ target: { name: "registrationdate", value: registrationdate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Start Date</span>
                  value={values.startdate}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={(startdate) => handleChange({ target: { name: "startdate", value: startdate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  label={<span>Expiry Date</span>}
                  value={values.expiredate ? dayjs(values.expiredate) : null}
                  slotProps={{ textField: { size: "small" } }}
                  style={{ marginTop: "2rem" }}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              <TextField //--------------------------------------Pan File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Pan"
                // onBlur={handleBlur}
                onChange={handleFileChangePan}
                // value={attachment1}
                name="pandocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onChange={handleFileChangePan}
                      />

                      {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Aadhar File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Aadhar"
                // onBlur={handleBlur}
                onChange={handleFileChangeAdhar}
                // value={attachment1}
                name="adhardocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeAdhar}
                      />
                       {selectedFileNameAdhar && (
                        <div>Selected file: {selectedFileNameAdhar}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------RC File Upload
                fullWidth
                variant="filled"
                type="text"
                label="RC"
                // onBlur={handleBlur}
                onChange={handleFileChangeRc}
                // value={attachment1}
                name="rcdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="rcdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeRc}
                      />
                       {selectedFileNameRc && (
                        <div>Selected file: {selectedFileNameRc}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Insurance File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Insurance"
                // onBlur={handleBlur}
                onChange={handleFileChangeinsurance}
                // value={attachment1}
                name="insurancedocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="insurancedocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeinsurance}
                      />
                       {selectedFileNameInsurance && (
                        <div>Selected file: {selectedFileNameInsurance}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField //------------------------------------------Other File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Other Document"
                // onBlur={handleBlur}
                onChange={handleFileChangeOther}
                sx={{ gridColumn: "span 2" }}
                name="otherdocument"
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="otherdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeOther}
                      />
                       {selectedFileNameOther && (
                        <div>Selected file: {selectedFileNameOther}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        )}

        {/* ---------------------------Fitness Form-------------------------------------- */}

        {showFitnessForm && (
          <form onSubmit={fitnessHandleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Owner Name"
                onChange={fitnessHandleChange}
                value={fitnessValues.ownername}
                name="ownername"
                size="small"
                error={fitnessTouched.ownername && !!fitnessErrors.ownername}
                helperText={
                  fitnessTouched.ownername && fitnessErrors.ownername
                    ? "Owner name is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Vechile Number"
                onChange={fitnessHandleChange}
                value={fitnessValues.vehicleno}
                name="vehicleno"
                error={fitnessTouched.vehicleno && !!fitnessErrors.vehicleno}
                helperText={
                  fitnessTouched.vehicleno && fitnessErrors.vehicleno
                    ? "Vehicle Number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Engine Number"
                onChange={fitnessHandleChange}
                value={fitnessValues.engineno}
                name="engineno"
                error={fitnessTouched.engineno && !!fitnessErrors.engineno}
                helperText={
                  fitnessTouched.engineno && fitnessErrors.engineno
                    ? "Engine Number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Chassis Number"
                onChange={fitnessHandleChange}
                value={fitnessValues.chassisno}
                name="chassisno"
                error={fitnessTouched.chassisno && !!fitnessErrors.chassisno}
                helperText={
                  fitnessTouched.chassisno && fitnessErrors.chassisno
                    ? "Chassis number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onChange={fitnessHandleChange}
                value={fitnessValues.phoneno}
                name="phoneno"
                error={fitnessTouched.phoneno && !!fitnessErrors.phoneno}
                helperText={
                  fitnessTouched.phoneno && fitnessErrors.phoneno
                    ? "Phone number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
                 <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Guardian Name"
                onChange={fitnessHandleChange}
                value={fitnessValues.guardianname}
                name="guardianname"
                sx={{ gridColumn: "span 2" }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Start Date</span>
                  value={fitnessValues.startdate}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={(startdate) => fitnessHandleChange({ target: { name: "startdate", value: startdate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              {/* insurance expire dcate */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Expiry Date</span>
                 // value={fitnessValues.expiredate}
                  value={fitnessValues.expiredate ? dayjs(fitnessValues.expiredate) : null}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={handleFitnessDateChange}
                  //onChange={(expiredate) => fitnessHandleChange({ target: { name: "expiredate", value: expiredate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              {userrole === "Superadmin" && companycode === 1 ? (
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
  <InputLabel id="companyCode-label">Company Code</InputLabel>
                <Select
                  labelId="companyCode-label"
                  id="companyCodes"
                  onChange={handleFitnessDateChange}
                  value={fitnessValues.companyCodes}
                  name="companyCodes"
                  error={fitnessTouched.companyCodes && !!fitnessErrors.companyCodes}
                  helperText={
                    fitnessTouched.companyCodes &&
                    fitnessErrors.companyCodes
                      ? ""
                      : ""
                  }
                  label="Company Code"
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="">Select Company Code</MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem
                      key={index}
                      value={code}
                    >
                      {code}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                ) : (
                  <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Company Code"
                  value={companycode}
                  sx={{ gridColumn: "span 2" }}
                />
              )}
              <TextField //--------------------------------------Pan File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Pan"
                // onBlur={handleBlur}
                onChange={handleFileChangePan}
                // value={attachment1}
                name="panedocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onChange={handleFileChangePan}
                      />
                     {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Aadhar File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Aadhar"
                // onBlur={handleBlur}
                onChange={handleFileChangeAdhar}
                // value={attachment1}
                name="adhardocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeAdhar}
                      />
                      {selectedFileNameAdhar && (
                        <div>Selected file: {selectedFileNameAdhar}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------RC File Upload
                fullWidth
                variant="filled"
                type="text"
                label="RC"
                // onBlur={handleBlur}
                onChange={handleFileChangeRc}
                // value={attachment1}
                name="rcdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="rcdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeRc}
                      />
                      {selectedFileNameRc && (
                        <div>Selected file: {selectedFileNameRc}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------RC File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Fitness"
                // onBlur={handleBlur}
                onChange={handleFileChangeFitness}
                // value={attachment1}
                name="fitnessdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="fitnessdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeFitness}
                      />
                      {selectedFileNameFitness && (
                        <div>Selected file: {selectedFileNameFitness}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField //------------------------------------------Other File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Other Document"
                // onBlur={handleBlur}
                onChange={handleFileChangeOther}
                // value={attachment1}
                name="otherdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="otherdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeOther}
                      />
                      {selectedFileNameOther && (
                        <div>Selected file: {selectedFileNameOther}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        )}

        {/* -------------------------------------Permit Form------------------------------------ */}

        {showPermitForm && (
          <form onSubmit={permitHandleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Owner Name"
                onChange={permitHandleChange}
                value={permitValues?.ownername || ""}
                name="ownername"
                size="small"
                error={permitTouched.ownername && !!permitErrors.ownername}
                helperText={
                  permitTouched.ownername && permitErrors.ownername
                    ? "Owner name is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onChange={permitHandleChange}
                value={permitValues?.address || ""}
                name="address"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Guardian Name"
                onChange={permitHandleChange}
                value={permitValues?.guardianname || ""}
                name="guardianname"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Vechile Number"
                onChange={permitHandleChange}
                value={permitValues?.vehicleno || ""}
                name="vehicleno"
                error={permitTouched.vehicleno && !!permitErrors.vehicleno}
                helperText={
                  permitTouched.vehicleno && permitErrors.vehicleno
                    ? "Vehicle number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onChange={permitHandleChange}
                value={permitValues?.phoneno || ""}
                name="phoneno"
                error={permitTouched.phoneno && !!permitErrors.phoneno}
                helperText={
                  permitTouched.phoneno && permitErrors.phoneno
                    ? "Phone number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              {userrole === "Superadmin" && companycode === 1 ?(
                <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
             <InputLabel id="companyCode-label">Company Code</InputLabel>
                <Select
                  labelId="companyCode-label"
                  id="companyCodes"
                  onChange={permitHandleChange}
                  value={permitValues.companyCodes}
                  name="companyCodes"
                  error={permitTouched.companyCodes && !!permitErrors.companyCodes}
                  helperText={
                    permitTouched.companyCodes &&
                    permitErrors.companyCodes
                      ? ""
                      : ""
                  }
                  label="Company Code"
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="">Select Company Code</MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem
                      key={index}
                      value={code}
                    >
                      {code}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                 ) : (
                  <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Company Code"
                  value={companycode}
                  name="companyCodes"
                  sx={{ gridColumn: "span 2" }}
                />
              )}
              <FormControl
                 fullWidth
                 variant="filled"
                 sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="permittype-label">
                  Select Permit Type <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  labelId="permittype-label"
                  id="permittype"
                  value={permitValues?.permittype || ""}
                  onChange={permitHandleChange}
                  name="permittype"
                  size="small"
                >
                  <MenuItem value="">
                    <em>Select Permit Type</em>
                  </MenuItem>
                  <MenuItem value="1 year">1 year</MenuItem>
                  <MenuItem value="5 years">5 years</MenuItem>
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Start Date</span>
                  value={permitValues.startdate }
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={(startdate) => permitHandleChange({ target: { name: "startdate", value: startdate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              {/* insurance expire dcate */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Expiry Date</span>
                 // value={permitValues.expiredate}
                  value={permitValues.expiredate ? dayjs(permitValues.expiredate) : null}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={handlePermitDateChange}
                 // onChange={(expiredate) => permitHandleChange({ target: { name: "expiredate", value: expiredate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              <TextField //--------------------------------------Pan File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Pan"
                // onBlur={handleBlur}
                onChange={handleFileChangePan}
                // value={attachment1}
                name="pandocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onChange={handleFileChangePan}
                      />
                      {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Aadhar File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Aadhar"
                // onBlur={handleBlur}
                onChange={handleFileChangeAdhar}
                // value={attachment1}
                name="adhardocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="adhardocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeAdhar}
                      />
                      {selectedFileNameAdhar && (
                        <div>Selected file: {selectedFileNameAdhar}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------RC File Upload
                fullWidth
                variant="filled"
                type="text"
                label="RC"
                // onBlur={handleBlur}
                onChange={handleFileChangeRc}
                // value={attachment1}
                name="rcdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="rcdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeRc}
                      />
                      {selectedFileNameRc && (
                        <div>Selected file: {selectedFileNameRc}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Permit File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Permit"
                // onBlur={handleBlur}
                onChange={handleFileChangepermit}
                // value={attachment1}
                name="permitdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="permitdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangepermit}
                      />
                      {selectedFileNamePermit && (
                        <div>Selected file: {selectedFileNamePermit}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField //------------------------------------------Other File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Other Document"
                // onBlur={handleBlur}
                onChange={handleFileChangeOther}
                // value={attachment1}
                name="otherdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="otherdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeOther}
                      />
                      {selectedFileNameOther && (
                        <div>Selected file: {selectedFileNameOther}</div>
                      )}
                    </InputAdornment>
                    
                  ),
                }}
              />
           
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        )}

        {/* -------------------------Pollution Form-------------------------------------- */}

        {showPollutionForm && (
          <form onSubmit={pollutionHandleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Owner Name"
                onChange={pollutionHandleChange}
                value={pollutionValues.ownername}
                name="ownername"
                error={pollutionTouched.ownername && !!pollutionErrors.ownername}
                helperText={
                  pollutionTouched.ownername && pollutionErrors.ownername
                    ? "Owner name is required"
                    : ""
                }
                size="small"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onChange={pollutionHandleChange}
                value={pollutionValues.address}
                name="address"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Guardian Name"
                onChange={pollutionHandleChange}
                value={pollutionValues.guardianname}
                name="guardianname"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Vechile Number"
                onChange={pollutionHandleChange}
                value={pollutionValues.vehicleno}
                name="vehicleno"
                error={pollutionTouched.vehicleno && !!pollutionErrors.vehicleno}
                helperText={
                  pollutionTouched.vehicleno && pollutionErrors.vehicleno
                    ? "Vehicle number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onChange={pollutionHandleChange}
                value={pollutionValues.phoneno}
                name="phoneno"
                error={pollutionTouched.phoneno && !!pollutionErrors.phoneno}
                helperText={
                  pollutionTouched.phoneno && pollutionErrors.phoneno
                    ? "Phone number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Registration Number"
                onChange={pollutionHandleChange}
                value={pollutionValues.registrationno}
                name="registrationno"
                error={pollutionTouched.registrationno && !!pollutionErrors.registrationno}
                helperText={
                  pollutionTouched.registrationno && pollutionErrors.registrationno
                    ? "Registration number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              {userrole === "Superadmin" && companycode === 1 ? (
               <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
             <InputLabel id="companyCode-label">Company Code</InputLabel>
                <Select
                  labelId="companyCode-label"
                  id="companyCodes"
                  onChange={pollutionHandleChange}
                  value={pollutionValues.companyCodes}
                  name="companyCodes"
                  error={pollutionTouched.companyCodes && !!pollutionErrors.companyCodes}
                  helperText={
                    pollutionTouched.companyCodes &&
                    pollutionErrors.companyCodes
                      ? ""
                      : ""
                  }
                  label="Company Code"
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="">Select Company Code</MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem
                      key={index}
                      value={code}
                    >
                      {code}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                 ) : (
                  <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Company Code"
                  value={companycode}
                  name="companyCodes"
                  sx={{ gridColumn: "span 2" }}
                />
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Registration Date</span>
                  value={pollutionValues?.registrationdate }
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={(registrationdate) => pollutionHandleChange({ target: { name: "registrationdate", value: registrationdate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Start Date</span>
                  value={pollutionValues.startdate}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={(startdate) => pollutionHandleChange({ target: { name: "startdate", value: startdate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              {/* insurance expire dcate */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Expiry Date</span>
                  //value={pollutionValues.expiredate}
                  value={pollutionValues.expiredate ? dayjs(pollutionValues.expiredate) : null}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={handlePollutionDateChange}
                 // onChange={(expiredate) => pollutionHandleChange({ target: { name: "expiredate", value: expiredate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              
              <TextField //--------------------------------------Pan File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Pan"
                // onBlur={handleBlur}
                onChange={handleFileChangePan}
                // value={attachment1}
                name="panedocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onChange={handleFileChangePan}
                      />
                      {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Aadhar File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Aadhar"
                // onBlur={handleBlur}
                onChange={handleFileChangeAdhar}
                // value={attachment1}
                name="adhardocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeAdhar}
                      />
                      {selectedFileNameAdhar && (
                        <div>Selected file: {selectedFileNameAdhar}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------RC File Upload
                fullWidth
                variant="filled"
                type="text"
                label="RC"
                // onBlur={handleBlur}
                onChange={handleFileChangeRc}
                // value={attachment1}
                name="rcdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="rcdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeRc}
                      />
                      {selectedFileNameRc && (
                        <div>Selected file: {selectedFileNameRc}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Insurance File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Pollution"
                // onBlur={handleBlur}
                onChange={handleFileChangePollution}
                // value={attachment1}
                name="pollutiondocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pollutiondocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangePollution}
                      />
                      {selectedFileNamePollution && (
                        <div>Selected file: {selectedFileNamePollution}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField //------------------------------------------Other File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Other Document"
                // onBlur={handleBlur}
                onChange={handleFileChangeOther}
                // value={attachment1}
                name="otherdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="otherdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeOther}
                      />
                      {selectedFileNameOther && (
                        <div>Selected file: {selectedFileNameOther}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        )}

        {showTaxForm && (
          <form onSubmit={taxHandleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Owner Name"
                onChange={taxHandleChange}
                value={taxValues.ownername}
                name="ownername"
                size="small"
                error={taxTouched.ownername && !!taxErrors.ownername}
                helperText={
                  taxTouched.ownername && taxErrors.ownername
                    ? "owner name is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onChange={taxHandleChange}
                value={taxValues.address}
                name="address"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Guardian Name"
                onChange={taxHandleChange}
                value={taxValues.guardianname}
                name="guardianname"
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Vechile Number"
                onChange={taxHandleChange}
                value={taxValues.vehicleno}
                name="vehicleno"
                error={taxTouched.vehicleno && !!taxErrors.vehicleno}
                helperText={
                  taxTouched.vehicleno && taxErrors.vehicleno
                    ? "Vehicle number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onChange={taxHandleChange}
                value={taxValues.phoneno}
                name="phoneno"
                error={taxTouched.phoneno && !!taxErrors.phoneno}
                helperText={
                  taxTouched.phoneno && taxErrors.phoneno
                    ? "Phone number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Chassis Number"
                onChange={taxHandleChange}
                value={taxValues.chassisno}
                name="chassisno"
                sx={{ gridColumn: "span 2" }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Start Date</span>
                  value={taxValues?.startdate }
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={(startdate) => taxHandleChange({ target: { name: "startdate", value: startdate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              {/* insurance expire dcate */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  //className={classes.customTextField}
                  label=<span>Expiry Date</span>
                  //value={taxValues.expiredate}
                  value={taxValues.expiredate ? dayjs(taxValues.expiredate) : null}
                  slotProps={{ textField: { size: "small" } }}
                  style={{
                    marginTop: "2rem",
                  }}
                  onChange={handleTaxDateChange}
                 // onChange={(expiredate) => taxHandleChange({ target: { name: "expiredate", value: expiredate } })}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              {userrole === "Superadmin" && companycode === 1 ? (
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
             <InputLabel id="companyCode-label">Company Code</InputLabel>
                <Select
                  labelId="companyCode-label"
                  id="companyCodes"
                  onChange={taxHandleChange}
                  value={taxValues.companyCodes}
                  name="companyCodes"
                  error={taxTouched.companyCodes && !!taxErrors.companyCodes}
                  helperText={
                    taxTouched.companyCodes &&
                    taxErrors.companyCodes
                      ? ""
                      : ""
                  }
                  label="Company Code"
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="">Select Company Code</MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem
                      key={index}
                      value={code}
                    >
                      {code}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                 ) : (
                  <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Company Code"
                  value={companycode}
                  sx={{ gridColumn: "span 2" }}
                />
              )}
              <TextField //--------------------------------------Pan File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Pan"
                // onBlur={handleBlur}
                onChange={handleFileChangePan}
                // value={attachment1}
                name="panedocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onChange={handleFileChangePan}
                      />
                      {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Aadhar File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Aadhar"
                // onBlur={handleBlur}
                onChange={handleFileChangeAdhar}
                // value={attachment1}
                name="adhardocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="pandocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeAdhar}
                      />
                      {selectedFileNameAdhar && (
                        <div>Selected file: {selectedFileNameAdhar}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------RC File Upload
                fullWidth
                variant="filled"
                type="text"
                label="RC"
                // onBlur={handleBlur}
                onChange={handleFileChangeRc}
                // value={attachment1}
                name="rcdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="rcdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeRc}
                      />
                      {selectedFileNameRc && (
                        <div>Selected file: {selectedFileNameRc}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField //------------------------------------------Insurance File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Pollution"
                // onBlur={handleBlur}
                onChange={handleFileChangeTax}
                // value={attachment1}
                name="taxdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="taxdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeTax}
                      />
                      {selectedFileNameTax && (
                        <div>Selected file: {selectedFileNameTax}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField //------------------------------------------Other File Upload
                fullWidth
                variant="filled"
                type="text"
                label="Other Document"
                // onBlur={handleBlur}
                onChange={handleFileChangeOther}
                // value={attachment1}
                name="otherdocument"
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  readOnly: true, // Disable text input
                  endAdornment: (
                    <InputAdornment position="start">
                      <UploadFileIcon
                        style={{ cursor: "pointer" }}
                        //onClick={() => handleIconClick()} // Corrected syntax
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        name="otherdocument"
                        //ref={fileInputRef}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        // onChange={handleFileChangePan}
                        onChange={handleFileChangeOther}
                      />
                      {selectedFileNameOther && (
                        <div>Selected file: {selectedFileNameOther}</div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Box>

      {/* Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default Addclient;

